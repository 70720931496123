import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ProgramAdminService {

  constructor(private http: HttpClient) { }

  // getPrograms(limit: number = 5, continuation?: string): Observable<any> {
  //   let url = `${environment.eml_sdk_api_url}exceptions/${this.programId}`;
  //   let params = new HttpParams();
  //   params = limit ? params.set('limit', '' + limit) : params;
  //   url += continuation ? `?continuation=${continuation}` : '';
  //   return this.http.get<any>(url, { headers: this.standardHeaders, params });
  // }
}
