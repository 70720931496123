import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {BatchStatusResponse} from '../models/batch-status-response';

@Injectable()
export class ReportService {

  constructor(private http: HttpClient) {
  }

  getReport(programId: string, reportName: string, reportYear: string, reportQuarter: string): Observable<any> {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/reports/${reportName}`
    + (reportYear != null ? `?reportYear=${reportYear}&reportQuarter=${reportQuarter}` : '');
    return this.http.get<any>(url, {responseType: 'text' as 'json', observe: 'response'});
  }

  getReportList(programId: string): Observable<any> {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/reports/fbt/list`;
    return this.http.get<Map<Number, number[]>[]>(url, {responseType: 'json', observe: 'response'});
  }
}
