import { Injectable } from '@angular/core';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { map, filter } from 'rxjs/operators';

export type MessageCallback = (payload: any) => void;

@Injectable()
export class BroadcastService {
    private _subject: BehaviorSubject<any> ;
    private item$:  Observable<any>;

    constructor() {
     this._subject = new BehaviorSubject<any>(1);
     this.item$  = this._subject.asObservable();
    }

    broadcast(type: string, payload: any) {
        this._subject.next({type , payload});
    }

    getSubject() {
        return this._subject;
    }

    getItem() {
        return this.item$;
    }

    subscribe(type: string, callback: MessageCallback): Subscription {
        return this.item$.pipe(
          filter(message => message.type === type),
          map(message => message.payload))
            .subscribe(callback);
    }

}
