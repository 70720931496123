import { Injectable } from '@angular/core';
import { UserAgentApplication } from 'msal';
import { environment } from '../../../environments/environment';
import { BroadcastService } from './broadcast.service';
import {
  MSAL_ERROR_MESSAGE_BLOCK_TOKEN_REQUESTS,
  MSAL_ERROR_MESSAGE_INTERACTION_REQUIRED,
  MSAL_ERROR_MESSAGE_USER_LOGIN_ERROR
} from '../constants';
import {throwError} from 'rxjs';

// const baseAuthURL = 'https://login.microsoftonline.com/tfp/';
const baseAuthURL = 'https://emlportal.b2clogin.com/';

const msalConfig = {
  auth: {
    clientId: environment.aad_app_id,
    authority: `${baseAuthURL}${environment.aad_tennant_id}/${environment.aad_signin_flow}`,
    validateAuthority: false
  },
  // cache: {
  //   storeAuthStateInCookie: true
  // },
  // framework: {
  //   isAngular: true
  // }
};

@Injectable()
export class MsalService extends UserAgentApplication {

  loginRequest = {
    scopes: [environment.aad_app_id]
  };

  processingRedirect = false;
  hasProcessedRedirect = false;

  constructor(private broadcastService: BroadcastService) {
    super(msalConfig);
    this.processingRedirect = false;
    this.hasProcessedRedirect = false;
    this.handleRedirectCallback(this.processRedirectCallback);
  }

  checkAuth = (): boolean => {
    return (this.getAccount() !== null && this.checkCacheforToken() !== null);
  }

  login = (): void => {
    // NOTE: A successful login or signup redirect will map to what ever is set to the 'handleRedirectCallback' on the msalInstance
    // This will automatically fire thanks to the MSAL library :)
    this.setAuthorityToSignIn();
    this.loginRedirect();
  }

  signup = (extraQueryParameters = {}): void => {
    // The should push a user to the SIGNIN flow
    this.setAuthorityToSignUp();
    this.loginRedirect({extraQueryParameters});
  }

  // Our custom handler for processing the redirect callback from Azure Active Directory B2C
  processRedirectCallback = (error, response) => {
    this.processingRedirect = true;
    this.hasProcessedRedirect = true;

    if (error) {
      // This here gives us an opportunity to intereact with any specific error messages are
      // are waiting from from teh Azure B2C platform.
      console.log('ERROR: Failure to correctly log user in on Azure Directory B2C Panel.');
      console.log(error);
      // This is the error code that is sent back using the password redirect

      /* FORGOT PASSWORD ERROR RESPONSE */
      /* This will be fired if a user clicks the forgot password link on the sign IN page */
      if (error.message.includes('AADB2C90118')) {
        this.passwordReset();
        /* SIGNUP ERROR RESPONSE */
        /* This will be fired if a user hits cancel on the sign UP page */
      } else if (error.message.includes('AADB2C90091')) {
        this.login();
        /* DEFAULT ERROR RESPONSE */
        /* All other redirect errors will fall back to pushing a user back to the login screen again */
      } else {
        this.login();
      }

      return;
    }

    // Due to having multiple flows, the authority gathered when processing a redirect needs to be changed here
    // to match what ever is on the token. We default this to the SIGN_IN flow, however it may not
    // be what we have authenticated the user with.
    const tokenAuthority = response.idTokenClaims.tfp;
    this.setAuthority(tokenAuthority);

    // Fire a login succes message!
    this.broadcastService.broadcast('msal:loginSuccess', response);

    // TODO: Expand this out to include other functions (such as different types of B2C Requests, reset password etc)
    // Ok lets start processing the response
    // this.acquireTokenSilentInternal();
    this.processingRedirect = false;
  }

  isProcessingRedirect = () => {
    return this.processingRedirect;
  }

  didProcessedRedirect = () => {
    return this.hasProcessedRedirect;
  }

  passwordReset = () => {
    this.setAuthority(environment.aad_forget_password_flow);
    this.loginRedirect();
  }

  setAuthorityToSignUp = () => {
    this.setAuthority(environment.aad_signup_flow);
  }

  setAuthorityToSignIn = () => {
    this.setAuthority(environment.aad_signin_flow);
  }

  setAuthority = (userFlow: string) => {
    this.authority = `${baseAuthURL}${environment.aad_tennant_id}/${userFlow}`;
  }

  checkCacheforToken = (): any => {
    const account = this.getAccount();
    if (account) {
      if (account.idToken && account.idToken.tfp) {
        const tokenAuthority = account.idToken.tfp;
        this.setAuthority(tokenAuthority);
      }
      const internalToken = this.getCachedTokenInternal(this.loginRequest.scopes, account, null);
      return internalToken;
    } else {
      return null;
    }
  }

  updateToken = (): Promise<boolean> => {
    return this.acquireTokenSilent(this.loginRequest)
      .then(() => {
        return true
      })
      .catch((error) => {
        console.log(`Token update failed. Error: ${error}.`)
        throw error
      })
  }

  _logout() {
    this.logout();
  }

}
