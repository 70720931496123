export class ProgramAccountCard {
  id: string;
  fname: string;
  lname: string;
  phone: string;
  mobile: string;
  email: string;
  status: string;
  client_account_key: string;
  external_account_id: string;
  cardholder_id: string;
  type: string;
  name: string;
  balance: number;
  account_details: {};
  card_renewal_details: any;
}
