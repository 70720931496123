import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as root from './root.reducer';
import { UserPermissions } from '../models/user-permissions';
import { RoutePermission } from '../models/route-permission';
import { ProgramPermissions } from '../models/program-permissions';
import { ProgramSearchModel, Cardholder } from '../../protected/shared-services-module/models';
import { CardholderState } from './init.cardholder.usertype/init.cardholder.usertype.state';
import { EmlAdminState } from './init.emladmin.usertype/init.emladmin.usertype.state';
import {Program} from '../../protected/program-admin-module/models/program';

export interface RootState {
  readonly logInStatus: number;
  readonly userPermissions: UserPermissions;
  readonly bootstrapError: string;
  readonly routePermissions: RoutePermission[];
  readonly programPermissions: ProgramPermissions;
  readonly sideMenuIsOpen: boolean;
  readonly userPrograms: ProgramSearchModel[];
  readonly selectedSidebarClient: any;
  readonly selectedSidebarProgram: ProgramSearchModel;
  readonly cardholder: Cardholder;
  readonly program?: Program;
  readonly cardholderState: CardholderState;
  readonly emlAdminState: EmlAdminState;
}

export const INITIAL_STATE: RootState = {
  logInStatus: 0,
  userPermissions: null,
  bootstrapError: null,
  routePermissions: null,
  programPermissions: null,
  sideMenuIsOpen: true,
  userPrograms: [],
  selectedSidebarClient: null,
  selectedSidebarProgram: null,
  cardholder: null,
  program: null,
  cardholderState: null,
  emlAdminState: null
};

export interface AppState {
    root: RootState;
}
