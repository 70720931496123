import { ProgramSearchModel } from './program-search-model';
// import { ProgramSearchSelectionCriteria } from './program-search-selection-criteria';
import { OrderingCriteria } from './ordering-criteria';
import { PagingCriteria } from './paging-criteria';
import { ProgramSearchSelectionCriteria } from './program-search-selection-criteria';

export class ProgramSearchResponse {
  selectionCriteria: ProgramSearchSelectionCriteria;
  orderingCriteria: OrderingCriteria;
  pagingCriteria: PagingCriteria;
  results: ProgramSearchModel[];
  error: string;
  total: number;
}
