import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class EmlSdkInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.eml_sdk_api_url)) {
      const clone = req.clone({
        headers: req.headers
          .set('Ocp-Apim-Subscription-Key', environment.eml_sdk_api_key)
          .set('Ocp-Apim-Trace', 'true')
          .set('X-Timezone-Offset-Minutes', ('' + (new Date().getTimezoneOffset())))
          //.set("X-Frame-Options", "sameorigin")
          //.set("X-Xss-Protection", "1; mode=block")
          //.set("X-Content-Type-Options", "nosniff")
          .set("Strict-Transport-Security", "max-age=31536000; includeSubDomains")
          //.set("Cache-control", "no-store")
          //.set("Pragma", "no-store")
          //.set("Arr-Disable-Session-Affinity", "true")
          //.set("Content-Security-Policy", "script-src 'self' 'unsafe-eval' secure.ewaypayments.com")
        }
      );
      return next.handle(clone);
    }
    return next.handle(req);
  }
}
