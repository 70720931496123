import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { mergeMap, switchMap, concatMap, catchError, tap, map, delay } from 'rxjs/operators';
import { ClientService } from '../../../protected/shared-services-module/services';
import * as fromRootActions from '../root.actions';
import * as fromActions from './init.emladmin.usertype.actions';

import { errorMapper } from '../../../protected/shared-services-module/services/error-mapper';

@Injectable()
export class InitEmlAdminUsertypeEffects {

  constructor(
    private actions: Actions,
    private clientService: ClientService
  ) { }


    @Effect()
    initEmlAdminUsertype$ = this.actions.pipe(
      ofType(fromRootActions.ActionTypes.INIT_EML_ADMIN_USERTYPE),
      mergeMap((action: fromRootActions.InitEmlAdminUsertypeAction) => {
        // This can be expanded to include any other initial, global required loads for the EML ADMIN usertype
        return [
          new fromActions.LoadClientsAction(),
        ];
      })
    );

    @Effect()
    loadClients$ = this.actions.pipe(
      ofType(fromActions.ActionTypes.LOAD_CLIENTS),
      switchMap((action: fromActions.LoadClientsAction) => {
        /* Debugging Action Handlers */
        // return of(new fromActions.LoadClientsSuccessfulAction()).pipe(delay(2000));
        // return of(new fromActions.LoadClientsFailedAction({errorMsg: 'Could not fetch clients from the database. Invalid login'})).pipe(delay(2000));
        return this.clientService.searchClients().pipe(
          map((response) => {
            response.results.forEach(element => {
              if(element.edit_secret == null){
                element.edit_secret = !element.api_secret_enabled;
              }
            });
            return new fromActions.LoadClientsSuccessfulAction(response);
          }),
          catchError((error) => {
            return of(new fromActions.LoadClientsFailedAction({ errorMsg: errorMapper(error)} ));
          })
        );
      })
    );
}