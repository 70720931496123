import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import {mergeMap, switchMap, concatMap, catchError, tap, map, flatMap} from 'rxjs/operators';
import { AuthorisationService } from '../../services/authorisation.service';
import * as fromRootActions from '../root.actions';
import * as fromActions from './init.cardholder.usertype.actions';
import { UserPermissions } from '../../models/user-permissions';
import { SearchResponse } from '../../../protected/shared-services-module/models/search-v2/search-response';
import { ProgramAccountCard, PagedSearchResult, ProgramSearchModel, Cardholder, TransactionSearchModel } from '../../../protected/shared-services-module/models';
import { AccountService, ProgramService, CardholderService } from '../../../protected/shared-services-module/services';
import { errorMapper } from '../../../protected/shared-services-module/services/error-mapper';
import {Program} from '../../../protected/program-admin-module/models/program';

@Injectable()
export class InitCardholderUsertypeEffects {

  constructor(
    private actions: Actions,
    private authService: AuthorisationService,
    private programService: ProgramService,
    private accountService: AccountService,
    private cardholderService: CardholderService) { }


  @Effect()
  loadCardholder$ = this.actions.pipe(
    ofType(fromRootActions.ActionTypes.INIT_CARDHOLDER_USERTYPE),
    switchMap((action: fromActions.LoadCardholderAction) => {
      return this.cardholderService.loadCardholder().pipe(
        flatMap((cardholder: Cardholder) => {
          return this.cardholderService.loadProgram(cardholder.program_id).pipe(
            map((program: Program) => {
              if (cardholder.status && cardholder.status.toLowerCase() === 'complete') {
                return new fromActions.LoadCardholderSuccessfulAction({
                  cardholder: cardholder,
                  program: program,
                });
              } else {
                const cardholderInactiveRedirectUrl = `/cardholder-accept/${cardholder.id}`;
                return new fromActions.LoadCardholderInactiveRedirectAction({cardholderInactiveRedirectUrl});
              }
            })
          );
        }),
        catchError((error) => {
          return of(new fromActions.LoadCardholderFailedAction({ error: errorMapper(error) }));
        })
      );
    })
  );

  @Effect()
  loadCardholderSuccessful$ = this.actions.pipe(
    ofType(fromActions.ActionTypes.LOAD_CARDHOLDER_SUCCESSFUL),
    switchMap((action: fromActions.LoadCardholderAction) => {
      const programId = action.payload.cardholder.program_id;
      const cardholderId = action.payload.cardholder.id;
      return of(new fromActions.LoadCardholderAccountsAction({programId, cardholderId}));
    })
  );

  @Effect()
  loadCardholderAccounts$ = this.actions.pipe(
    ofType(fromActions.ActionTypes.LOAD_CARDHOLDER_ACCOUNTS),
    mergeMap((action: fromActions.LoadCardholderAccountsAction) => {
      const { programId, cardholderId } = action.payload;
      return this.cardholderService.fetchCardholderAccounts(programId, cardholderId).pipe(
        map((accounts: SearchResponse<ProgramAccountCard>) => {
          return new fromActions.LoadCardholderAccountsSuccessfulAction({
            programId,
            cardholderId,
            accounts,
          });
        }),
        catchError((error) => {
          return of(new fromActions.LoadCardholderAccountsFailedAction({ error: errorMapper(error) }));
        })
      );
    })
  );

  @Effect()
  loadCardholderAccountsSuccessful$ = this.actions.pipe(
    ofType(fromActions.ActionTypes.LOAD_CARDHOLDER_ACCOUNTS_SUCCESSFUL),
    switchMap((action: fromActions.LoadCardholderAccountsSuccessfulAction) => {
      const { programId, cardholderId, accounts } = action.payload;
      const actions = [];
      accounts.results.forEach(account => actions.push(
        new fromActions.LoadCardholderAccountTransactionsAction({
          accountId: account.id,
          externalAccountId: account.external_account_id,
        })
      ));
      return actions;
    })
  );

  @Effect()
  loadAccountTransactions$ = this.actions.pipe(
    ofType(fromActions.ActionTypes.LOAD_CARDHOLDER_ACCOUNT_TRANSACTIONS),
    concatMap((action: fromActions.LoadCardholderAccountTransactionsAction) => {
      const { accountId, externalAccountId, criteria } = action.payload;
      return this.accountService.getTransactions(externalAccountId, criteria).pipe(
        map((searchResults: PagedSearchResult<TransactionSearchModel>) => {
          if (!searchResults.results) {
            searchResults.results = [];
          }
          return new fromActions.LoadCardholderAccountTransactionsSuccessfulAction({
            accountId,
            externalAccountId,
            results: searchResults
          });
        }),
        catchError((error) => {
          return of(new fromActions.LoadCardholderAccountTransactionsFailedAction({ error: errorMapper(error) }));
        })
      );
    })
  );

}
