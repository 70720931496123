import * as rootActions from './root.actions';
import * as initCardholderUsertypeActions from './init.cardholder.usertype/init.cardholder.usertype.actions';
import * as initEmlAdminUsertypeActions from './init.emladmin.usertype/init.emladmin.usertype.actions';
import * as fromManageCardsModuleActions from '../../protected/manage-cards-module/store/actions';
import * as fromDashboardModuleEmlAdminDashboardActions from '../../protected/dashboard-module/store/eml-admin-dashboard-actions';
import * as rootState from './root.state';
import { UserPermissions } from '../models/user-permissions';
import { RoutePermission } from '../models/route-permission';
import { ProgramPermissions } from '../models/program-permissions';
import { ActivationEnd } from '@angular/router';
import { Action } from 'rxjs/internal/scheduler/Action';
import { ProgramAccountCard } from '../../protected/shared-services-module/models/program-account-card';

export const reducers = {
  root: reducer
};

export function reducer(state = rootState.INITIAL_STATE, action: rootActions.ActionsUnion): rootState.RootState {
  switch (action.type) {
    case rootActions.ActionTypes.LOGIN: {
      return {
        ...state,
        logInStatus: 2,
      };
    }
    case rootActions.ActionTypes.LOGIN_FAILED: {
      return {
        ...state,
        logInStatus: 0,
      };
    }
    case rootActions.ActionTypes.LOGIN_SUCCEEDED: {
      return {
        ...state,
        logInStatus: 2,
      };
    }
    case rootActions.ActionTypes.LOGOUT: {
      return {
        ...state,
        logInStatus: 1,
      };
    }
    case rootActions.ActionTypes.LOGOUT_FAILED: {
      return {
        ...state,
        logInStatus: 2,
      };
    }
    case rootActions.ActionTypes.LOGOUT_SUCCEEDED: {
      return JSON.parse(JSON.stringify(rootState.INITIAL_STATE));
    }
    case rootActions.ActionTypes.USER_PERMISSIONS_FAILED: {
      return {
        ...state,
        userPermissions: null,
        bootstrapError: action.payload
      };
    }
    case rootActions.ActionTypes.USER_PERMISSIONS_SUCCEEDED: {
      return {
        ...state,
        userPermissions: action.payload.clientPermissions,
        routePermissions: action.payload.routePermissions,
        programPermissions: action.payload.programPermissions
      };
    }
    case 'SET_ROUTE_PERMISSION': {
      const newRoutePermissions = state.routePermissions
        .map((perm) => perm.route !== action.payload.route ? perm : { ...perm, permission: action.payload.permission });
      return {
        ...state,
        routePermissions: newRoutePermissions
      };
    }
    case rootActions.ActionTypes.SIDE_MENU_COLLAPSE: {
      return {
        ...state,
        sideMenuIsOpen: false,
      };
    }
    case rootActions.ActionTypes.SIDE_MENU_EXPAND: {
      return {
        ...state,
        sideMenuIsOpen: true,
      };
    }
    case rootActions.ActionTypes.SET_SIDEBAR_USER_PROGRAM: {
      return {
        ...state,
        selectedSidebarProgram: action.payload
      };
    }
    case rootActions.ActionTypes.CLEAR_SIDEBAR_USER_PROGRAM: {
      return {
        ...state,
        selectedSidebarProgram: null
      };
    }
    case rootActions.ActionTypes.SET_SIDEBAR_USER_CLIENT: {
      return {
        ...state,
        selectedSidebarClient: action.payload,
        userPrograms: null
      };
    }
    case rootActions.ActionTypes.CLEAR_SIDEBAR_USER_CLIENT: {
      return {
        ...state,
        selectedSidebarClient: null,
        userPrograms: null
      };
    }
    case rootActions.ActionTypes.LOAD_USER_PROGRAMS_SUCCESSFUL: {
      return {
        ...state,
        userPrograms: action.payload.programs
      };
    }
    // ************************************************************************
    // CARDHOLDER USERTYPE ACTIONS
    // ************************************************************************
    case initCardholderUsertypeActions.ActionTypes.LOAD_CARDHOLDER_SUCCESSFUL: {
      const { cardholder, program } = action.payload;
      return {
        ...state,
        cardholder,
        program,
      };
    }
    case initCardholderUsertypeActions.ActionTypes.LOAD_CARDHOLDER_FAILED: {
      return {
        ...state,
        cardholder: null
      };
    }
    case initCardholderUsertypeActions.ActionTypes.LOAD_CARDHOLDER_INACTIVE_REDIRECT: {
      return {
        ...state,
        cardholderState: {
          ...state.cardholderState,
          cardholderInactiveRedirectUrl: action.payload.cardholderInactiveRedirectUrl
        }
      };
    }
    case initCardholderUsertypeActions.ActionTypes.CLEAR_CARDHOLDER_INACTIVE_REDIRECT: {
      return {
        ...state,
        cardholderState: {
          ...state.cardholderState,
          cardholderInactiveRedirectUrl: null
        }
      };
    }

    case initCardholderUsertypeActions.ActionTypes.LOAD_CARDHOLDER_ACCOUNTS_SUCCESSFUL: {
      const { accounts } = action.payload as initCardholderUsertypeActions.LoadCardholderAccountsSuccessfulPayload;
      const accountList = accounts && accounts.results || [];
      return {
        ...state,
        cardholderState: {
          ...state.cardholderState,
          accounts: accountList,
        }
      };
    }
    case initCardholderUsertypeActions.ActionTypes.LOAD_CARDHOLDER_ACCOUNT_TRANSACTIONS: {
      return {
        ...state,
        cardholderState: {
          ...state.cardholderState,
          transactionSearchError: null,
          transactionSearchInFlight: true,
        }
      };
    }
    case initCardholderUsertypeActions.ActionTypes.LOAD_CARDHOLDER_ACCOUNT_TRANSACTIONS_SUCCESSFUL: {
      const { accountId, externalAccountId, results } = action.payload as initCardholderUsertypeActions.LoadCardholderAccountTransactionsSuccessfulPayload;
      const accountTransactionMap = { ...state.cardholderState.accountTransactionMap };
      accountTransactionMap[accountId] = results;
      return {
        ...state,
        cardholderState: {
          ...state.cardholderState,
          transactionSearchError: null,
          transactionSearchInFlight: false,
          accountTransactionMap
        }
      };
    }
    case initCardholderUsertypeActions.ActionTypes.LOAD_CARDHOLDER_ACCOUNT_TRANSACTIONS_FAILED: {
      const { error } = action.payload as initCardholderUsertypeActions.LoadCardholderAccountTransactionsFailedPayload;
      return {
        ...state,
        cardholderState: {
          ...state.cardholderState,
          transactionSearchError: error,
          transactionSearchInFlight: false,
        }
      };
    }
    // ************************************************************************
    // INIT EML ADMIN USERTYPE ACTIONS
    // ************************************************************************
    case initEmlAdminUsertypeActions.ActionTypes.LOAD_CLIENTS: {
      return {
        ...state,
        emlAdminState: {
          ...state.emlAdminState,
          clients: null,
          clientsInFlight: true,
          clientsError: null
        }
      };
    }
    case initEmlAdminUsertypeActions.ActionTypes.LOAD_CLIENTS_FAILED: {
      return {
        ...state,
        emlAdminState: {
          ...state.emlAdminState,
          clients: null,
          clientsInFlight: false,
          clientsError: action.payload.errorMsg
        }
      };
    }
    case initEmlAdminUsertypeActions.ActionTypes.LOAD_CLIENTS_SUCCESSFUL: {
      return {
        ...state,
        emlAdminState: {
          ...state.emlAdminState,
          clients: action.payload.results,
          clientsInFlight: false,
          clientsError: null
        }
      };
    }
    case fromDashboardModuleEmlAdminDashboardActions.ActionTypes.EDIT_CLIENT_SUCCEEDED: {
      const currentClients = state.emlAdminState.clients;
      const newClients = currentClients.map((c) => (c.client_id === action.payload.client_id) ? action.payload : c);
      return {
        ...state,
        emlAdminState: {
          ...state.emlAdminState,
          clients: newClients
        }
      };
    }
    case fromDashboardModuleEmlAdminDashboardActions.ActionTypes.CREATE_CLIENT_SUCCEEDED: {
      return {
        ...state,
        emlAdminState: {
          ...state.emlAdminState,
          clients: state.emlAdminState.clients.concat([action.payload])
        }
      };
    }
    // ************************************************************************
    // MANAGE CARDS MODULE GLOBAL ACTIONS
    // ************************************************************************
    case fromManageCardsModuleActions.ActionTypes.CREATE_CARD_SUCCESSFUL: {
      const currentAccounts = state.cardholderState.accounts;
      const newAccounts = state.cardholderState.accounts.concat([action.payload]);
      return {
        ...state,
        cardholderState: {
          ...state.cardholderState,
          accounts: newAccounts,
        }
      };
    }
    case fromManageCardsModuleActions.ActionTypes.CANCEL_CARD_SUCCESSFUL: {
      if (state.cardholderState && state.cardholderState.accounts) {
        const newAccounts = state.cardholderState.accounts.map((a) => {
          return (a.id === action.payload.id) ? {
            ...a,
            status: action.payload.status
          } : a;
        });
        return {
          ...state,
          cardholderState: {
            ...state.cardholderState,
            accounts: newAccounts,
          }
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case fromManageCardsModuleActions.ActionTypes.ACTIVATE_CARD_SUCCESSFUL: {
      if (action.payload && action.payload.results) {
        return {
          ...state,
          cardholderState: {
            ...state.cardholderState,
            accounts: action.payload.results
          }
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case fromManageCardsModuleActions.ActionTypes.ACTIVATE_RENEWAL_CARD_WITH_OTP_SUCCESSFUL: {
      const updatedResults = state.cardholderState.accounts.map(account => {
        if (account.external_account_id === action.payload) {
            return {                            
                ...account,
                card_renewal_details: {
                    ...account.card_renewal_details,
                    status: 'activated',
                    activated_date: new Date().toLocaleDateString()
                }
            };
        }
        return account;
      });
      return {
        ...state,
        cardholderState: {
          ...state.cardholderState,
          accounts: updatedResults
        }
      };
    }
    case fromManageCardsModuleActions.ActionTypes.UPDATE_CARDHOLDER_SUCCESSFUL: {
      return {
        ...state,
        cardholder: action.payload || state.cardholder
      };
    }
    default: {
      return state;
    }
  }
}
