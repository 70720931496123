import { PROGRAM_ACCESS_ROLES } from '../../protected/shared-constants/program-access-roles';

export interface RoleShape {
  id: string;
  name: string;
}

export interface RouteShape {
  id: string;
  path: string;
}

export interface ProgramShape {
  id: string;
}

export interface PermissionShape {
  id: number;
  resourceType: string;
  resourceId: string;
  roleId: string;
  permission: string;
}

export interface AuthDb {
  readonly roles: RoleShape[];
  readonly routes: RouteShape[];
  programs: ProgramShape[];
  readonly permissions: PermissionShape[];
}

export const db: AuthDb = {
  roles: [
    { id: 'r01', name: 'eml-admin' },
    { id: 'r02', name: 'eml-risk' },
    { id: 'r03', name: 'eml-client-support' },
    { id: 'r04', name: 'eml-maintain' },
    { id: 'r05', name: 'eml-view' },
    { id: 'r06', name: 'eml-no-access' },
    { id: 'r13', name: 'client-admin' },
    { id: 'r14', name: 'client-approve' },
    { id: 'r15', name: 'client-transact' },
    { id: 'r16', name: 'client-maintain' },
    { id: 'r17', name: 'client-view' },
    { id: 'r18', name: 'client-no-access' },
    { id: 'r19', name: 'cardholder' },
  ],
  routes: [
    { id: 'route01', path: 'dashboard' },
    { id: 'route02', path: 'disbursement' },
    { id: 'route03', path: 'card-search' },
    { id: 'route04', path: 'client-admin' },
    { id: 'route05', path: 'user-management' },
    { id: 'route06', path: 'program-admin' },
    { id: 'route07', path: 'exceptions' },
    { id: 'route08', path: 'report-a-problem' },
    { id: 'route09', path: 'manage-cards' },
    { id: 'route10', path: 'applications' },
    { id: 'route11', path: 'create-cardholder' },
    { id: 'route12', path: 'pending-applications' },
    { id: 'route13', path: 'view-activity' },
    { id: 'route14', path: 'reporting' },
    { id: 'route15', path: 'program-user-management' },
  ],
  programs: [],
  permissions: [
    { id: 1, resourceType: 'route', resourceId: 'route01', roleId: 'r01', permission: 'R' },
    { id: 2, resourceType: 'route', resourceId: 'route01', roleId: 'r02', permission: 'R' },
    { id: 3, resourceType: 'route', resourceId: 'route01', roleId: 'r03', permission: 'R' },
    { id: 4, resourceType: 'route', resourceId: 'route01', roleId: 'r04', permission: 'R' },
    { id: 5, resourceType: 'route', resourceId: 'route01', roleId: 'r05', permission: 'R' },
    { id: 6, resourceType: 'route', resourceId: 'route01', roleId: 'r06', permission: 'X' },
    { id: 13, resourceType: 'route', resourceId: 'route01', roleId: 'r13', permission: 'R' },
    { id: 14, resourceType: 'route', resourceId: 'route01', roleId: 'r14', permission: 'R' },
    { id: 15, resourceType: 'route', resourceId: 'route01', roleId: 'r15', permission: 'R' },
    { id: 16, resourceType: 'route', resourceId: 'route01', roleId: 'r16', permission: 'R' },
    { id: 17, resourceType: 'route', resourceId: 'route01', roleId: 'r17', permission: 'R' },
    { id: 18, resourceType: 'route', resourceId: 'route01', roleId: 'r18', permission: 'X' },
    { id: 19, resourceType: 'route', resourceId: 'route01', roleId: 'r19', permission: 'R' },

    { id: 21, resourceType: 'route', resourceId: 'route02', roleId: 'r01', permission: 'W' },
    { id: 22, resourceType: 'route', resourceId: 'route02', roleId: 'r02', permission: 'R' },
    { id: 23, resourceType: 'route', resourceId: 'route02', roleId: 'r03', permission: 'W' },
    { id: 24, resourceType: 'route', resourceId: 'route02', roleId: 'r04', permission: 'R' },
    { id: 25, resourceType: 'route', resourceId: 'route02', roleId: 'r05', permission: 'R' },
    { id: 26, resourceType: 'route', resourceId: 'route02', roleId: 'r06', permission: 'X' },
    { id: 33, resourceType: 'route', resourceId: 'route02', roleId: 'r13', permission: 'R' },
    { id: 34, resourceType: 'route', resourceId: 'route02', roleId: 'r14', permission: 'R' },
    { id: 35, resourceType: 'route', resourceId: 'route02', roleId: 'r15', permission: 'R' },
    { id: 36, resourceType: 'route', resourceId: 'route02', roleId: 'r16', permission: 'R' },
    { id: 37, resourceType: 'route', resourceId: 'route02', roleId: 'r17', permission: 'R' },
    { id: 38, resourceType: 'route', resourceId: 'route02', roleId: 'r18', permission: 'X' },
    { id: 39, resourceType: 'route', resourceId: 'route02', roleId: 'r19', permission: 'X' },

    { id: 40, resourceType: 'route', resourceId: 'route03', roleId: 'r01', permission: 'W' },
    { id: 41, resourceType: 'route', resourceId: 'route03', roleId: 'r02', permission: 'W' },
    { id: 42, resourceType: 'route', resourceId: 'route03', roleId: 'r03', permission: 'W' },
    { id: 43, resourceType: 'route', resourceId: 'route03', roleId: 'r04', permission: 'W' },
    { id: 44, resourceType: 'route', resourceId: 'route03', roleId: 'r05', permission: 'R' },
    { id: 45, resourceType: 'route', resourceId: 'route03', roleId: 'r06', permission: 'X' },
    { id: 52, resourceType: 'route', resourceId: 'route03', roleId: 'r13', permission: 'W' },
    { id: 53, resourceType: 'route', resourceId: 'route03', roleId: 'r14', permission: 'W' },
    { id: 54, resourceType: 'route', resourceId: 'route03', roleId: 'r15', permission: 'W' },
    { id: 55, resourceType: 'route', resourceId: 'route03', roleId: 'r16', permission: 'W' },
    { id: 56, resourceType: 'route', resourceId: 'route03', roleId: 'r17', permission: 'W' },
    { id: 57, resourceType: 'route', resourceId: 'route03', roleId: 'r18', permission: 'X' },
    { id: 58, resourceType: 'route', resourceId: 'route03', roleId: 'r19', permission: 'X' },

    { id: 59, resourceType: 'route', resourceId: 'route04', roleId: 'r01', permission: 'W' },
    { id: 60, resourceType: 'route', resourceId: 'route04', roleId: 'r02', permission: 'W' },
    { id: 61, resourceType: 'route', resourceId: 'route04', roleId: 'r03', permission: 'W' },
    { id: 62, resourceType: 'route', resourceId: 'route04', roleId: 'r04', permission: 'R' },
    { id: 63, resourceType: 'route', resourceId: 'route04', roleId: 'r05', permission: 'R' },
    { id: 64, resourceType: 'route', resourceId: 'route04', roleId: 'r06', permission: 'X' },
    { id: 71, resourceType: 'route', resourceId: 'route04', roleId: 'r13', permission: 'X' },
    { id: 72, resourceType: 'route', resourceId: 'route04', roleId: 'r14', permission: 'R' },
    { id: 73, resourceType: 'route', resourceId: 'route04', roleId: 'r15', permission: 'R' },
    { id: 74, resourceType: 'route', resourceId: 'route04', roleId: 'r16', permission: 'R' },
    { id: 75, resourceType: 'route', resourceId: 'route04', roleId: 'r17', permission: 'R' },
    { id: 76, resourceType: 'route', resourceId: 'route04', roleId: 'r18', permission: 'X' },
    { id: 77, resourceType: 'route', resourceId: 'route04', roleId: 'r19', permission: 'X' },

    { id: 78, resourceType: 'route', resourceId: 'route05', roleId: 'r01', permission: 'W' },
    { id: 79, resourceType: 'route', resourceId: 'route05', roleId: 'r02', permission: 'X' },
    { id: 80, resourceType: 'route', resourceId: 'route05', roleId: 'r03', permission: 'X' },
    { id: 81, resourceType: 'route', resourceId: 'route05', roleId: 'r04', permission: 'X' },
    { id: 82, resourceType: 'route', resourceId: 'route05', roleId: 'r05', permission: 'X' },
    { id: 83, resourceType: 'route', resourceId: 'route05', roleId: 'r06', permission: 'X' },
    { id: 90, resourceType: 'route', resourceId: 'route05', roleId: 'r13', permission: 'W' },
    { id: 91, resourceType: 'route', resourceId: 'route05', roleId: 'r14', permission: 'X' },
    { id: 92, resourceType: 'route', resourceId: 'route05', roleId: 'r15', permission: 'X' },
    { id: 93, resourceType: 'route', resourceId: 'route05', roleId: 'r16', permission: 'X' },
    { id: 94, resourceType: 'route', resourceId: 'route05', roleId: 'r17', permission: 'X' },
    { id: 95, resourceType: 'route', resourceId: 'route05', roleId: 'r18', permission: 'X' },
    { id: 96, resourceType: 'route', resourceId: 'route05', roleId: 'r19', permission: 'X' },

    { id: 97, resourceType: 'route', resourceId: 'route06', roleId: 'r01', permission: 'W' },
    { id: 98, resourceType: 'route', resourceId: 'route06', roleId: 'r02', permission: 'W' },
    { id: 99, resourceType: 'route', resourceId: 'route06', roleId: 'r03', permission: 'W' },
    { id: 100, resourceType: 'route', resourceId: 'route06', roleId: 'r04', permission: 'R' },
    { id: 101, resourceType: 'route', resourceId: 'route06', roleId: 'r05', permission: 'R' },
    { id: 102, resourceType: 'route', resourceId: 'route06', roleId: 'r06', permission: 'X' },
    { id: 109, resourceType: 'route', resourceId: 'route06', roleId: 'r13', permission: 'R' },
    { id: 110, resourceType: 'route', resourceId: 'route06', roleId: 'r14', permission: 'R' },
    { id: 111, resourceType: 'route', resourceId: 'route06', roleId: 'r15', permission: 'R' },
    { id: 112, resourceType: 'route', resourceId: 'route06', roleId: 'r16', permission: 'R' },
    { id: 113, resourceType: 'route', resourceId: 'route06', roleId: 'r17', permission: 'R' },
    { id: 114, resourceType: 'route', resourceId: 'route06', roleId: 'r18', permission: 'X' },
    { id: 115, resourceType: 'route', resourceId: 'route06', roleId: 'r19', permission: 'X' },

    { id: 116, resourceType: 'route', resourceId: 'route07', roleId: 'r01', permission: 'W' },
    { id: 117, resourceType: 'route', resourceId: 'route07', roleId: 'r02', permission: 'W' },
    { id: 118, resourceType: 'route', resourceId: 'route07', roleId: 'r03', permission: 'W' },
    { id: 119, resourceType: 'route', resourceId: 'route07', roleId: 'r04', permission: 'R' },
    { id: 120, resourceType: 'route', resourceId: 'route07', roleId: 'r05', permission: 'R' },
    { id: 121, resourceType: 'route', resourceId: 'route07', roleId: 'r06', permission: 'X' },
    { id: 128, resourceType: 'route', resourceId: 'route07', roleId: 'r13', permission: 'X' },
    { id: 129, resourceType: 'route', resourceId: 'route07', roleId: 'r14', permission: 'X' },
    { id: 130, resourceType: 'route', resourceId: 'route07', roleId: 'r15', permission: 'X' },
    { id: 131, resourceType: 'route', resourceId: 'route07', roleId: 'r16', permission: 'X' },
    { id: 132, resourceType: 'route', resourceId: 'route07', roleId: 'r17', permission: 'X' },
    { id: 133, resourceType: 'route', resourceId: 'route07', roleId: 'r18', permission: 'X' },
    { id: 134, resourceType: 'route', resourceId: 'route07', roleId: 'r19', permission: 'X' },

    { id: 116, resourceType: 'route', resourceId: 'route08', roleId: 'r01', permission: 'X' },
    { id: 117, resourceType: 'route', resourceId: 'route08', roleId: 'r02', permission: 'X' },
    { id: 118, resourceType: 'route', resourceId: 'route08', roleId: 'r03', permission: 'X' },
    { id: 119, resourceType: 'route', resourceId: 'route08', roleId: 'r04', permission: 'X' },
    { id: 120, resourceType: 'route', resourceId: 'route08', roleId: 'r05', permission: 'X' },
    { id: 121, resourceType: 'route', resourceId: 'route08', roleId: 'r06', permission: 'X' },
    { id: 128, resourceType: 'route', resourceId: 'route08', roleId: 'r13', permission: 'X' },
    { id: 129, resourceType: 'route', resourceId: 'route08', roleId: 'r14', permission: 'X' },
    { id: 130, resourceType: 'route', resourceId: 'route08', roleId: 'r15', permission: 'X' },
    { id: 131, resourceType: 'route', resourceId: 'route08', roleId: 'r16', permission: 'X' },
    { id: 132, resourceType: 'route', resourceId: 'route08', roleId: 'r17', permission: 'X' },
    { id: 133, resourceType: 'route', resourceId: 'route08', roleId: 'r18', permission: 'X' },
    { id: 134, resourceType: 'route', resourceId: 'route08', roleId: 'r19', permission: 'W' },

    { id: 135, resourceType: 'route', resourceId: 'route09', roleId: 'r01', permission: 'X' },
    { id: 136, resourceType: 'route', resourceId: 'route09', roleId: 'r02', permission: 'X' },
    { id: 137, resourceType: 'route', resourceId: 'route09', roleId: 'r03', permission: 'X' },
    { id: 138, resourceType: 'route', resourceId: 'route09', roleId: 'r04', permission: 'X' },
    { id: 139, resourceType: 'route', resourceId: 'route09', roleId: 'r05', permission: 'X' },
    { id: 140, resourceType: 'route', resourceId: 'route09', roleId: 'r06', permission: 'X' },
    { id: 147, resourceType: 'route', resourceId: 'route09', roleId: 'r13', permission: 'X' },
    { id: 148, resourceType: 'route', resourceId: 'route09', roleId: 'r14', permission: 'X' },
    { id: 149, resourceType: 'route', resourceId: 'route09', roleId: 'r15', permission: 'X' },
    { id: 150, resourceType: 'route', resourceId: 'route09', roleId: 'r16', permission: 'X' },
    { id: 151, resourceType: 'route', resourceId: 'route09', roleId: 'r17', permission: 'X' },
    { id: 152, resourceType: 'route', resourceId: 'route09', roleId: 'r18', permission: 'X' },
    { id: 153, resourceType: 'route', resourceId: 'route09', roleId: 'r19', permission: 'W' },

    { id: 154, resourceType: 'route', resourceId: 'route10', roleId: 'r01', permission: 'W' },
    { id: 155, resourceType: 'route', resourceId: 'route10', roleId: 'r02', permission: 'R' },
    { id: 156, resourceType: 'route', resourceId: 'route10', roleId: 'r03', permission: 'W' },
    { id: 157, resourceType: 'route', resourceId: 'route10', roleId: 'r04', permission: 'W' },
    { id: 158, resourceType: 'route', resourceId: 'route10', roleId: 'r05', permission: 'R' },
    { id: 159, resourceType: 'route', resourceId: 'route10', roleId: 'r06', permission: 'X' },
    { id: 166, resourceType: 'route', resourceId: 'route10', roleId: 'r13', permission: 'W' },
    { id: 167, resourceType: 'route', resourceId: 'route10', roleId: 'r14', permission: 'W' },
    { id: 168, resourceType: 'route', resourceId: 'route10', roleId: 'r15', permission: 'W' },
    { id: 169, resourceType: 'route', resourceId: 'route10', roleId: 'r16', permission: 'W' },
    { id: 170, resourceType: 'route', resourceId: 'route10', roleId: 'r17', permission: 'R' },
    { id: 171, resourceType: 'route', resourceId: 'route10', roleId: 'r18', permission: 'X' },
    { id: 172, resourceType: 'route', resourceId: 'route10', roleId: 'r19', permission: 'X' },

    { id: 173, resourceType: 'route', resourceId: 'route11', roleId: 'r01', permission: 'W' },
    { id: 174, resourceType: 'route', resourceId: 'route11', roleId: 'r02', permission: 'R' },
    { id: 175, resourceType: 'route', resourceId: 'route11', roleId: 'r03', permission: 'W' },
    { id: 176, resourceType: 'route', resourceId: 'route11', roleId: 'r04', permission: 'W' },
    { id: 177, resourceType: 'route', resourceId: 'route11', roleId: 'r05', permission: 'R' },
    { id: 178, resourceType: 'route', resourceId: 'route11', roleId: 'r06', permission: 'X' },
    { id: 185, resourceType: 'route', resourceId: 'route11', roleId: 'r13', permission: 'W' },
    { id: 186, resourceType: 'route', resourceId: 'route11', roleId: 'r14', permission: 'W' },
    { id: 187, resourceType: 'route', resourceId: 'route11', roleId: 'r15', permission: 'W' },
    { id: 188, resourceType: 'route', resourceId: 'route11', roleId: 'r16', permission: 'W' },
    { id: 189, resourceType: 'route', resourceId: 'route11', roleId: 'r17', permission: 'R' },
    { id: 190, resourceType: 'route', resourceId: 'route11', roleId: 'r18', permission: 'X' },
    { id: 191, resourceType: 'route', resourceId: 'route11', roleId: 'r19', permission: 'X' },

    { id: 173, resourceType: 'route', resourceId: 'route12', roleId: 'r01', permission: 'W' },
    { id: 174, resourceType: 'route', resourceId: 'route12', roleId: 'r02', permission: 'R' },
    { id: 175, resourceType: 'route', resourceId: 'route12', roleId: 'r03', permission: 'W' },
    { id: 176, resourceType: 'route', resourceId: 'route12', roleId: 'r04', permission: 'W' },
    { id: 177, resourceType: 'route', resourceId: 'route12', roleId: 'r05', permission: 'R' },
    { id: 178, resourceType: 'route', resourceId: 'route12', roleId: 'r06', permission: 'X' },
    { id: 185, resourceType: 'route', resourceId: 'route12', roleId: 'r13', permission: 'W' },
    { id: 186, resourceType: 'route', resourceId: 'route12', roleId: 'r14', permission: 'W' },
    { id: 187, resourceType: 'route', resourceId: 'route12', roleId: 'r15', permission: 'W' },
    { id: 188, resourceType: 'route', resourceId: 'route12', roleId: 'r16', permission: 'W' },
    { id: 189, resourceType: 'route', resourceId: 'route12', roleId: 'r17', permission: 'R' },
    { id: 190, resourceType: 'route', resourceId: 'route12', roleId: 'r18', permission: 'X' },
    { id: 191, resourceType: 'route', resourceId: 'route12', roleId: 'r19', permission: 'X' },

    // - /view-activity Route Permissions by user type
    { id: 192, resourceType: 'route', resourceId: 'route13', roleId: 'r01', permission: 'W' },
    { id: 193, resourceType: 'route', resourceId: 'route13', roleId: 'r02', permission: 'X' },
    { id: 194, resourceType: 'route', resourceId: 'route13', roleId: 'r03', permission: 'X' },
    { id: 195, resourceType: 'route', resourceId: 'route13', roleId: 'r04', permission: 'X' },
    { id: 196, resourceType: 'route', resourceId: 'route13', roleId: 'r05', permission: 'X' },
    { id: 197, resourceType: 'route', resourceId: 'route13', roleId: 'r06', permission: 'X' },
    { id: 198, resourceType: 'route', resourceId: 'route13', roleId: 'r13', permission: 'X' },
    { id: 199, resourceType: 'route', resourceId: 'route13', roleId: 'r14', permission: 'X' },
    { id: 200, resourceType: 'route', resourceId: 'route13', roleId: 'r15', permission: 'X' },
    { id: 201, resourceType: 'route', resourceId: 'route13', roleId: 'r16', permission: 'X' },
    { id: 202, resourceType: 'route', resourceId: 'route13', roleId: 'r17', permission: 'X' },
    { id: 203, resourceType: 'route', resourceId: 'route13', roleId: 'r18', permission: 'X' },
    { id: 204, resourceType: 'route', resourceId: 'route13', roleId: 'r19', permission: 'X' },

    // - /reporting Route Permissions by user type
    { id: 205, resourceType: 'route', resourceId: 'route14', roleId: 'r01', permission: 'W' },
    { id: 206, resourceType: 'route', resourceId: 'route14', roleId: 'r02', permission: 'W' },
    { id: 207, resourceType: 'route', resourceId: 'route14', roleId: 'r03', permission: 'W' },
    { id: 208, resourceType: 'route', resourceId: 'route14', roleId: 'r04', permission: 'W' },
    { id: 209, resourceType: 'route', resourceId: 'route14', roleId: 'r05', permission: 'W' },
    { id: 210, resourceType: 'route', resourceId: 'route14', roleId: 'r06', permission: 'X' },
    { id: 211, resourceType: 'route', resourceId: 'route14', roleId: 'r13', permission: 'W' },
    { id: 212, resourceType: 'route', resourceId: 'route14', roleId: 'r14', permission: 'W' },
    { id: 213, resourceType: 'route', resourceId: 'route14', roleId: 'r15', permission: 'W' },
    { id: 214, resourceType: 'route', resourceId: 'route14', roleId: 'r16', permission: 'W' },
    { id: 215, resourceType: 'route', resourceId: 'route14', roleId: 'r17', permission: 'W' },
    { id: 216, resourceType: 'route', resourceId: 'route14', roleId: 'r18', permission: 'W' },
    { id: 217, resourceType: 'route', resourceId: 'route14', roleId: 'r19', permission: 'X' },

    { id: 218, resourceType: 'route', resourceId: 'route15', roleId: 'r01', permission: 'W' },
    { id: 219, resourceType: 'route', resourceId: 'route15', roleId: 'r02', permission: 'W' },
    { id: 220, resourceType: 'route', resourceId: 'route15', roleId: 'r03', permission: 'W' },
    { id: 221, resourceType: 'route', resourceId: 'route15', roleId: 'r04', permission: 'W' },
    { id: 222, resourceType: 'route', resourceId: 'route15', roleId: 'r05', permission: 'W' },
    { id: 223, resourceType: 'route', resourceId: 'route15', roleId: 'r06', permission: 'W' },
    { id: 224, resourceType: 'route', resourceId: 'route15', roleId: 'r13', permission: 'W' },
    { id: 225, resourceType: 'route', resourceId: 'route15', roleId: 'r14', permission: 'W' },
    { id: 226, resourceType: 'route', resourceId: 'route15', roleId: 'r15', permission: 'W' },
    { id: 227, resourceType: 'route', resourceId: 'route15', roleId: 'r16', permission: 'W' },
    { id: 228, resourceType: 'route', resourceId: 'route15', roleId: 'r17', permission: 'W' },
    { id: 229, resourceType: 'route', resourceId: 'route15', roleId: 'r18', permission: 'W' },
    { id: 230, resourceType: 'route', resourceId: 'route15', roleId: 'r19', permission: 'W' },

  ]
};

export const programPermissionsMapper = (accessLevel) => {
  const programPermissions = {};
  PROGRAM_PERMISSION_TYPES.forEach((key) => {
    programPermissions[key] = PROGRAM_PERMISSIONS_MAP[key][accessLevel];
  });
  return programPermissions;
};

export const PROGRAM_PERMISSION_KEYS = {
  PROGRAM_CREATE_CARDHOLDER: 'PROGRAM_CREATE_CARDHOLDER',
  PROGRAM_REPORTING: 'PROGRAM_REPORTING',
  PROGRAM_PENDING_APPLICATIONS: 'PROGRAM_PENDING_APPLICATIONS',
  PROGRAM_APPLICATION_EXCEPTIONS: 'PROGRAM_APPLICATION_EXCEPTIONS',
  PROGRAM_CARD_SEARCH: 'PROGRAM_CARD_SEARCH',
  MAINTAIN_CARDS_CARDS_LIST: 'MAINTAIN_CARDS_CARDS_LIST',
  MAINTAIN_CARDS_VIEW: 'MAINTAIN_CARDS_VIEW',
  MAINTAIN_CARDS_CARDHOLDER_DETAILS: 'MAINTAIN_CARDS_CARDHOLDER_DETAILS',
  MAINTAIN_CARDS_MANAGE_CARD: 'MAINTAIN_CARDS_MANAGE_CARD',
  MAINTAIN_CARDS_TRANSFER: 'MAINTAIN_CARDS_TRANSFER',
  MAINTAIN_CARDS_ADD_A_PROXY_CARD: 'MAINTAIN_CARDS_ADD_A_PROXY_CARD',
  MAINTAIN_CARDS_ADD_A_BENEFIT: 'MAINTAIN_CARDS_ADD_A_BENEFIT',
  DISBURSEMENTS_MAIN_SCREEN: 'DISBURSEMENTS_MAIN_SCREEN',
  DISBURSEMENTS_TRANSACTION_HISTORY: 'DISBURSEMENTS_TRANSACTION_HISTORY',
  DISBURSEMENTS_BULK_TRANSFER: 'DISBURSEMENTS_BULK_TRANSFER',
  DISBURSEMENTS_DIRECT_ENTRY_OUT: 'DISBURSEMENTS_DIRECT_ENTRY_OUT',
};

export const PROGRAM_PERMISSION_TYPES = Object.keys(PROGRAM_PERMISSION_KEYS);

const PERM = {
  EDIT: 'E',
  VIEW: 'V',
  NONE: 'X'
};

const generatePermissions = (ADMINISTRATOR, APPROVE, TRANSACT, MAINTAIN, VIEW, NO_ACCESS) => {
  return {
    [PROGRAM_ACCESS_ROLES.ADMINISTRATOR]: ADMINISTRATOR,
    [PROGRAM_ACCESS_ROLES.APPROVE]: APPROVE,
    [PROGRAM_ACCESS_ROLES.TRANSACT]: TRANSACT,
    [PROGRAM_ACCESS_ROLES.MAINTAIN]: MAINTAIN,
    [PROGRAM_ACCESS_ROLES.VIEW]: VIEW,
    [PROGRAM_ACCESS_ROLES.NO_ACCESS]: NO_ACCESS
  };
};

export const PROGRAM_PERMISSIONS_MAP = {
  PROGRAM_CREATE_CARDHOLDER: generatePermissions(PERM.EDIT, PERM.EDIT, PERM.EDIT, PERM.NONE, PERM.NONE, PERM.NONE),
  PROGRAM_REPORTING: generatePermissions(PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.NONE),
  PROGRAM_PENDING_APPLICATIONS: generatePermissions(PERM.EDIT, PERM.EDIT, PERM.EDIT, PERM.EDIT, PERM.VIEW, PERM.NONE),
  PROGRAM_APPLICATION_EXCEPTIONS: generatePermissions(PERM.EDIT, PERM.EDIT, PERM.EDIT, PERM.EDIT, PERM.VIEW, PERM.NONE),
  PROGRAM_CARD_SEARCH: generatePermissions(PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.NONE),
  MAINTAIN_CARDS_CARDS_LIST: generatePermissions(PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.NONE),
  MAINTAIN_CARDS_VIEW: generatePermissions(PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.NONE),
  MAINTAIN_CARDS_CARDHOLDER_DETAILS: generatePermissions(PERM.EDIT, PERM.EDIT, PERM.EDIT, PERM.EDIT, PERM.VIEW, PERM.NONE),
  MAINTAIN_CARDS_MANAGE_CARD: generatePermissions(PERM.EDIT, PERM.EDIT, PERM.EDIT, PERM.EDIT, PERM.NONE, PERM.NONE),
  MAINTAIN_CARDS_TRANSFER: generatePermissions(PERM.EDIT, PERM.EDIT, PERM.NONE, PERM.NONE, PERM.NONE, PERM.NONE),
  MAINTAIN_CARDS_ADD_A_PROXY_CARD: generatePermissions(PERM.EDIT, PERM.EDIT, PERM.EDIT, PERM.EDIT, PERM.NONE, PERM.NONE),
  MAINTAIN_CARDS_ADD_A_BENEFIT: generatePermissions(PERM.EDIT, PERM.EDIT, PERM.EDIT, PERM.EDIT, PERM.NONE, PERM.NONE),
  DISBURSEMENTS_MAIN_SCREEN: generatePermissions(PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.NONE),
  DISBURSEMENTS_TRANSACTION_HISTORY: generatePermissions(PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.VIEW, PERM.NONE),
  DISBURSEMENTS_BULK_TRANSFER: generatePermissions(PERM.EDIT, PERM.EDIT, PERM.NONE, PERM.NONE, PERM.NONE, PERM.NONE),
  DISBURSEMENTS_DIRECT_ENTRY_OUT: generatePermissions(PERM.EDIT, PERM.EDIT, PERM.NONE, PERM.NONE, PERM.NONE, PERM.NONE),
};

export interface ScreenResource {
  id: string;
  name: string;
}

export interface ResourcePermission {
  id: string;
  resourceType: string;
  resourceId: string;
  roleId: string;
  permission: string;
}
