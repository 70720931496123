import {HttpErrorResponse} from '@angular/common/http';
import {stringify} from '@angular/compiler/src/util';

export function errorMapper(errorResponse: any): string {
  if (typeof (errorResponse) === 'string') {
    return errorResponse;
  }
  if (typeof (errorResponse) === 'object' && !Array.isArray(errorResponse)) {
    if (errorResponse['status'] && typeof (errorResponse['status']) === 'number') {
      if (errorResponse['error'] && typeof (errorResponse['error']) === 'string') {
        return errorResponse['error'];
      } else if (errorResponse['error'] && typeof (errorResponse['error']) === 'object' && !Array.isArray(errorResponse['error'])) {
        if (errorResponse['error']['ExceptionMessage'] && typeof (errorResponse['error']['ExceptionMessage']) === 'string') {
          try {
            const error_description_obj = JSON.parse(errorResponse['error']['ExceptionMessage']);
            if (typeof (error_description_obj) === 'string') {
              return error_description_obj;
            } else if (typeof (error_description_obj) === 'object' && !Array.isArray(error_description_obj) &&
              error_description_obj['error_description'] && typeof (error_description_obj['error_description']) === 'string') {
              return error_description_obj['error_description'];
            }
          } catch {
            return errorResponse['error']['ExceptionMessage'];
          }
        } else if (errorResponse['error'] && Array.isArray(errorResponse['error']['errors'])) {
          return errorResponse['error'] // this case caters for a collection of errors under a single error. ie: Bad Request 400 with message, then multiple errors showing validation issues for each affected field.
        } else if (errorResponse['error'] && errorResponse['error']['Message']) {
          return errorResponse['error']['Message'];
        } else if (errorResponse['error'] && errorResponse['error']['message']) {
          return errorResponse['error']['message'];
        } else if (errorResponse['error'] && errorResponse['error']['description']) {
          return errorResponse['error']['description'];
        } else if (errorResponse['error'] && errorResponse['error']['error_description']) {
          return errorResponse['error']['error_description'];
        } else if (errorResponse['statusText'] && typeof (errorResponse['statusText']) === 'string') {
          return errorResponse['statusText'];
        }
      } else if (errorResponse['message'] && typeof (errorResponse['message']) === 'string') {
        return errorResponse['message'];
      } else if (errorResponse['error'] && typeof (errorResponse['error']) === 'string') {
        return errorResponse['error'];
      }
    }
  }
  return 'Unknown Error';
}

/*

"{
  "headers": {
    "normalizedNames": {},
    "lazyUpdate": null
  },
  "status": 400,
  "statusText": "Bad Request",
  "url": "https://cmp-beta-api.emlpayments.com/mgmt/v1/programs/%7B1/payments/upload",
  "ok": false,
  "name": "HttpErrorResponse",
  "message": "Http failure response for https://cmp-beta-api.emlpayments.com/mgmt/v1/programs/%7B1/payments/upload: 400 Bad Request",
  "error": "csv file not found"
}"

*/
