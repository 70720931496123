import { Action } from '@ngrx/store';
import { FEATURE_NAME } from '../constants';

export class ActionTypes {
  static readonly INIT_EML_ADMIN_DASHBOARD = `[${FEATURE_NAME}] INIT_EML_ADMIN_DASHBOARD`;
  static readonly SET_EML_ADMIN_DASHBOARD_ACTIVE_VIEW = `[${FEATURE_NAME}] SET_EML_ADMIN_DASHBOARD_ACTIVE_VIEW`;
  static readonly SHOW_EDIT_CLIENT = `[${FEATURE_NAME}] SHOW_EDIT_CLIENT`;
  static readonly CLOSE_EDIT_CLIENT = `[${FEATURE_NAME}] CLOSE_EDIT_CLIENT`;
  static readonly EDIT_CLIENT = `[${FEATURE_NAME}] EDIT_CLIENT`;
  static readonly EDIT_CLIENT_FAILED = `[${FEATURE_NAME}] EDIT_CLIENT_FAILED`;
  static readonly EDIT_CLIENT_SUCCEEDED = `[${FEATURE_NAME}] EDIT_CLIENT_SUCCEEDED`;
  static readonly CREATE_CLIENT = `[${FEATURE_NAME}] CREATE_CLIENT`;
  static readonly CREATE_CLIENT_FAILED = `[${FEATURE_NAME}] CREATE_CLIENT_FAILED`;
  static readonly CREATE_CLIENT_SUCCEEDED = `[${FEATURE_NAME}] CREATE_CLIENT_SUCCEEDED`;
  static readonly REFRESH_CLIENT_TOKEN = `[${FEATURE_NAME}] REFRESH_CLIENT_TOKEN`;
  static readonly REFRESH_CLIENT_TOKEN_FAILED = `[${FEATURE_NAME}] REFRESH_CLIENT_TOKEN_FAILED`;
  static readonly REFRESH_CLIENT_TOKEN_SUCCEEDED = `[${FEATURE_NAME}] REFRESH_CLIENT_TOKEN_SUCCEEDED`;
}

// ============================================================================

export interface InitEmlAdminDashboardPayload {
  programId: string;
  cardholderId: string;
}

export class InitEmlAdminDashboardAction implements Action {
  readonly type = ActionTypes.INIT_EML_ADMIN_DASHBOARD;
  constructor(readonly payload: InitEmlAdminDashboardPayload = null) { }
}

// ============================================================================

export interface SetEmlAdminDashboardActiveViewPayload {
  activeView: string;
}

export class SetEmlAdminDashboardActiveViewAction implements Action {
  readonly type = ActionTypes.SET_EML_ADMIN_DASHBOARD_ACTIVE_VIEW;
  constructor(readonly payload: SetEmlAdminDashboardActiveViewPayload = null) { }
}

// ============================================================================

export class ShowEditClientAction implements Action {
  readonly type = ActionTypes.SHOW_EDIT_CLIENT;
  constructor(public payload: any) { }
}

export class CloseEditClientAction implements Action {
  readonly type = ActionTypes.CLOSE_EDIT_CLIENT;
  constructor() { }
}

export class EditClientAction implements Action {
  readonly type = ActionTypes.EDIT_CLIENT;
  constructor(public payload: any) { }
}

export class EditClientFailedAction implements Action {
  readonly type = ActionTypes.EDIT_CLIENT_FAILED;
  constructor(public payload: any) { }
}

export class EditClientSuccessfulAction implements Action {
  readonly type = ActionTypes.EDIT_CLIENT_SUCCEEDED;
  constructor(public payload: any) { }
}

export class CreateClientAction implements Action {
  readonly type = ActionTypes.CREATE_CLIENT;
  constructor(public payload: any) { }
}

export class CreateClientFailedAction implements Action {
  readonly type = ActionTypes.CREATE_CLIENT_FAILED;
  constructor(public payload: any) { }
}

export class CreateClientSuccessfulAction implements Action {
  readonly type = ActionTypes.CREATE_CLIENT_SUCCEEDED;
  constructor(public payload: any) { }
}

export class RefreshClientTokenAction implements Action {
  readonly type = ActionTypes.REFRESH_CLIENT_TOKEN;
  constructor(public payload: any) { }
}

export class RefreshClientTokenActionFailedAction implements Action {
  readonly type = ActionTypes.REFRESH_CLIENT_TOKEN_FAILED;
  constructor(public payload: any) { }
}

export class RefreshClientTokenActionSuccessfulAction implements Action {
  readonly type = ActionTypes.REFRESH_CLIENT_TOKEN_SUCCEEDED;
  constructor(public payload: any) { }
}

export type Actions =
  InitEmlAdminDashboardAction
  | SetEmlAdminDashboardActiveViewAction
  | ShowEditClientAction
  | CloseEditClientAction
  | EditClientAction
  | EditClientFailedAction
  | EditClientSuccessfulAction
  | CreateClientAction
  | CreateClientFailedAction
  | CreateClientSuccessfulAction
  | RefreshClientTokenAction;
