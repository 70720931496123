import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay } from 'rxjs/operators';
import {
  Client,
  ClientSearchCriteria,
  ClientSearchModel,
  ClientSearchResponse,
  ProgramSearchCriteria,
  ProgramSearchModel,
  ProgramSearchResponse,
  SortOrder,
} from '../models';

@Injectable()
export class ClientAdminService {

  private _clients: Client[] = [
    { id: '100542', name: 'Paul Penner', status: 'active' },
    { id: '100543', name: 'Walker Standardbreds', status: 'active' },
    { id: '100544', name: 'Ameri-Net', status: 'active' },
    { id: '100545', name: 'AG BOT', status: 'active' },
    { id: '100546', name: 'Ferguson Electric Co', status: 'active' },
    { id: '100547', name: 'Reynolds John', status: 'active' },
    { id: '100548', name: 'Cinnabar Ranch', status: 'active' },
    { id: '100549', name: 'Individual', status: 'active' },
    { id: '100630', name: 'Personal', status: 'active' },
    { id: '100631', name: 'The Collaborative Institute', status: 'active' },
    { id: '100632', name: 'Design', status: 'active' },
    { id: '100633', name: 'Del Monte Foods CO', status: 'active' },
    { id: '100634', name: 'Kettle Hills Farm', status: 'active' },
    { id: '100635', name: 'Becss.com', status: 'cancelled' },
    { id: '100636', name: 'Edemco Dryers Inc', status: 'active' },
    { id: '100637', name: 'Central Oregonian', status: 'active' },
    { id: '100638', name: 'Erik Rudl', status: 'active' },
    { id: '100639', name: 'Puthoff & Shumaker', status: 'active' },
    { id: '100720', name: 'Jesse L. Young', status: 'active' },
    { id: '100721', name: 'Gillette Boarding Kennels', status: 'cancelled' },
    { id: '100722', name: 'Jeffus & Associates', status: 'active' },
    { id: '100723', name: 'Jay Petersen', status: 'active' },
    { id: '100724', name: 'Matthew Daly', status: 'active' },
    { id: '100725', name: 'Walker Farm', status: 'active' },
    { id: '100726', name: 'Bill Sheets', status: 'cancelled' },
    { id: '100727', name: 'Circle L Ranch', status: 'active' },
    { id: '100728', name: 'Leadcity.com', status: 'active' },
    { id: '100729', name: 'Holiday Barn Pet Resorts', status: 'active' },
    { id: '100870', name: 'Joseph Buppert Farm', status: 'active' },
    { id: '100871', name: 'Sloan Securities', status: 'active' },
    { id: '100872', name: 'Petsmart', status: 'active' },
    { id: '100873', name: 'Daniels Farms', status: 'cancelled' },
    { id: '100874', name: 'May Farms', status: 'active' },
    { id: '100875', name: 'Wilar Enterprises', status: 'active' },
    { id: '100876', name: 'Extrameasures', status: 'active' },
    { id: '100877', name: 'Precision Horse Training', status: 'active' },
    { id: '100878', name: 'Friesian Dreams Farm', status: 'active' },
    { id: '100879', name: 'St.bernard', status: 'active' },
    { id: '100920', name: 'Cooney Fertilizer Inc', status: 'active' },
    { id: '100921', name: 'Live Oak Kennel', status: 'active' },
    { id: '100922', name: 'Farms View Road Stand', status: 'active' },
    { id: '100923', name: 'Dick\'s Customizing & Collision', status: 'active' },
    { id: '100924', name: 'Martins GOT You Covered', status: 'active' },
    { id: '100925', name: 'TAC Farms Inc', status: 'active' },
    { id: '100926', name: 'Sundowner Trailers Of Tulsa', status: 'active' },
    { id: '100927', name: 'Quality Grain Systems Inc', status: 'active' },
    { id: '100928', name: 'Anna E Nogales Md - Valley Eye Center', status: 'active' },
    { id: '100929', name: 'Kdp Consulting Associates', status: 'active' },
    { id: '101077', name: 'Stefan Holodnick', status: 'active' },
    { id: '101075', name: 'Fat Cat Flats', status: 'active' },
    { id: '102092', name: 'Zorba\'s Chippy', status: 'sunset' },
  ];

  private _programs: ProgramSearchModel[] = [
    {
      id: '3000',
      clientId: '100545',
      company_id: '',
      type: 'salpack',
      status: 'Active',
      currency: '$',
      name: 'Salary Packaging Program',
      active_card_count: 0,
      inactive_card_count: 0,
      total_cards: 0,
      stored_value: 0,
      disbursement_value: 0,
      pending_count: 0,
      pending_value: 0,
      exception_count: 0,
      sms_configured: false,
      sendgrid_template_id: '',
      active_le_card_count: 0,
      active_me_card_count: 0,
      cardholder_pending_application_count: 0,
      cardholder_active_application_count: 0,
      disable_transaction_statements: false,
      gift_program_claim_type: 0,
      disbursement_allow_de_out: false
    }, {
      id: '3010',
      clientId: '100545',
      company_id: '',
      type: 'reloadable',
      status: 'Active',
      currency: '$',
      name: 'Reloadable Program',
      active_card_count: 0,
      inactive_card_count: 0,
      total_cards: 0,
      stored_value: 0,
      disbursement_value: 0,
      pending_count: 0,
      pending_value: 0,
      exception_count: 0,
      sms_configured: false,
      sendgrid_template_id: '',
      active_le_card_count: 0,
      active_me_card_count: 0,
      cardholder_pending_application_count: 0,
      cardholder_active_application_count: 0,
      disable_transaction_statements: true,
      gift_program_claim_type: 0,
      disbursement_allow_de_out: false
    }, {
      id: '3020',
      clientId: '100545',
      company_id: '',
      type: 'gift',
      status: 'Active',
      currency: '$',
      name: 'Instant Gift',
      active_card_count: 0,
      inactive_card_count: 0,
      total_cards: 0,
      stored_value: 0,
      disbursement_value: 0,
      pending_count: 0,
      pending_value: 0,
      exception_count: 0,
      sms_configured: false,
      sendgrid_template_id: '',
      active_le_card_count: 0,
      active_me_card_count: 0,
      cardholder_pending_application_count: 0,
      cardholder_active_application_count: 0,
      disable_transaction_statements: true,
      gift_program_claim_type: 0,
      disbursement_allow_de_out: false
    },
  ];

  private _program_cards: ProgramCard[] = [
    { id: '3000', totalCards: 9 },
    { id: '3010', totalCards: 40 },
    { id: '3020', totalCards: 700 }
  ];

  constructor(private http: HttpClient) { }

  searchForClients(criteria: ClientSearchCriteria): Observable<ClientSearchResponse> {
    return this.mockSearchForClients(criteria).pipe(delay(300));
    // const url = `${environment.eml_sdk_api_url}client/search`;
    // let params = new HttpParams();
    // params = params.set('offset', '' + offset);
    // params = params.set('limit', '' + limit);
    // return this.http.post<any>(url, { headers: this.standardHeaders, criteria });
  }
  private mockSearchForClients(criteria: ClientSearchCriteria): Observable<ClientSearchResponse> {
    const { selectionCriteria, orderingCriteria, pagingCriteria } = criteria;
    const allMatchingResults: ClientSearchModel[] = this._clients
      .map((client: Client) => {
        const res = new ClientSearchModel();
        res.id = client.id;
        res.name = client.name;
        res.status = client.status;
        res.activeProgramCount = 0;
        return res;
      })
      .filter((client: ClientSearchModel) => {
        if (!selectionCriteria) {
          return true;
        } else if (selectionCriteria.clientName !== '' || selectionCriteria.clientStatus !== '' ||
            selectionCriteria.programName !== '' || selectionCriteria.programType !== '') {
          let keep = false;
          if (!keep && selectionCriteria.clientName !== '') {
            if (client.name.toLowerCase().indexOf(selectionCriteria.clientName.toLowerCase()) !== -1) {
              keep = true;
            }
          }
          if (!keep && selectionCriteria.clientStatus !== '') {
            if (client.status.toLowerCase() === selectionCriteria.clientStatus.toLowerCase()) {
              keep = true;
            }
          }
          return keep;
        } else {
          return true;
        }
      });
    allMatchingResults
      .sort((left, right) => {
        let res = 0;
        if (!orderingCriteria) {
          return res;
        }
        if (left.hasOwnProperty(orderingCriteria.fieldName) && right.hasOwnProperty(orderingCriteria.fieldName)) {
          const leftFieldValue = left[orderingCriteria.fieldName];
          const rightFieldValue = right[orderingCriteria.fieldName];
          res = leftFieldValue > rightFieldValue ? 1 : leftFieldValue < rightFieldValue ? -1 : 0;
        } else {
          return 0;
        }
        return (orderingCriteria.order === SortOrder.Unknown || orderingCriteria.order === SortOrder.Ascending)
          ? res
          : res * -1;
      });
    const pageOfResults = allMatchingResults.reduce((result, item, index, array) => {
        return index >= pagingCriteria.offset && index < pagingCriteria.offset + pagingCriteria.limit
          ? [ ... result, item ]
          : result;
      }, []);
    const res = new ClientSearchResponse();
    res.error = null;
    res.selectionCriteria = { ...selectionCriteria };
    res.orderingCriteria = { ...orderingCriteria };
    res.pagingCriteria = { ...pagingCriteria };
    res.results = pageOfResults;
    res.total = allMatchingResults.length;
    return of(res);
  }

  createClient(client: Client): Observable<Client> {
    return this.mockCreateClient(client).pipe(delay(300));
    // const url = `${environment.eml_sdk_api_url}client`;
    // const params = new HttpParams();
    // return this.http.post<any>(url, { headers: this.standardHeaders, client });
  }
  private mockCreateClient(client: Client): Observable<Client> {
    const id = '' + (this._clients.reduce((p, c) => +c.id > p ? +c.id : p, 0) + 1);
    const newClient = new ClientSearchModel();
    newClient.id = id;
    newClient.name = client.name;
    newClient.status = client.status;
    newClient.activeProgramCount = 0;
    this._clients = [ ...this._clients, newClient ];
    return of(JSON.parse(JSON.stringify(newClient)) as Client);
  }

  updateClient(client: Client): Observable<{}> {
    return this.mockUpdateClient(client).pipe(delay(1000));
    // const url = `${environment.eml_sdk_api_url}client/${client.id}`;
    // const params = new HttpParams();
    // return this.http.post<any>(url, { headers: this.standardHeaders, client });
  }
  private mockUpdateClient(client: Client): Observable<{}> {
    const existingClient = this._clients.find((c) => c.id === client.id);
    if (!existingClient) {
      return throwError('not found');
    }
    this._clients.map((c) => {
      if (c.id === client.id) {
        return {
          id: c.id,
          name: client.name,
          status: client.status,
        } as Client;
      } else {
        return c;
      }
    });
    return of({...client});
  }

  searchForPrograms(clientId: string, criteria: ProgramSearchCriteria): Observable<ProgramSearchResponse> {
    return this.mockSearchForPrograms(clientId, criteria).pipe(delay(300));
  }
  private mockSearchForPrograms(clientId: string, criteria: ProgramSearchCriteria): Observable<ProgramSearchResponse> {
    const { selectionCriteria, orderingCriteria, pagingCriteria } = criteria;
    const allMatchingResults: ProgramSearchModel[] = this._programs
      .filter((program: ProgramSearchModel) => program.clientId === clientId)
      .map((program: ProgramSearchModel) => {
        const res = {
          ...program,
          total_cards: this._program_cards.find((pc) => pc.id === program.id).totalCards
        };
        return res;
      });
      // .filter((program: ProgramSearchModel) => {
      //   if (!selectionCriteria) {
      //     return true;
      //   } else if (selectionCriteria.programName !== '' || selectionCriteria.programType !=='' || selectionCriteria.programName !== '') {
      //     let keep = false;
      //     if (!keep && selectionCriteria.clientName !== '') {
      //       if (client.name.toLowerCase().indexOf(selectionCriteria.clientName.toLowerCase()) !== -1) {
      //         keep = true;
      //       }
      //     }
      //     if (!keep && selectionCriteria.clientStatus !== '') {
      //       if (client.status.toLowerCase() === selectionCriteria.clientStatus.toLowerCase()) {
      //         keep = true;
      //       }
      //     }
      //     return keep;
      //   } else {
      //     return true;
      //   }
      // });
    allMatchingResults
      .sort((left, right) => {
        let res = 0;
        if (!orderingCriteria) {
          return res;
        }
        if (left.hasOwnProperty(orderingCriteria.fieldName) && right.hasOwnProperty(orderingCriteria.fieldName)) {
          const leftFieldValue = left[orderingCriteria.fieldName];
          const rightFieldValue = right[orderingCriteria.fieldName];
          res = leftFieldValue > rightFieldValue ? 1 : leftFieldValue < rightFieldValue ? -1 : 0;
        } else {
          return 0;
        }
        return (orderingCriteria.order === SortOrder.Unknown || orderingCriteria.order === SortOrder.Ascending)
          ? res
          : res * -1;
      });
    const pageOfResults = allMatchingResults.reduce((result, item, index, array) => {
        return index >= pagingCriteria.offset && index < pagingCriteria.offset + pagingCriteria.limit
          ? [ ... result, item ]
          : result;
      }, []);
    const res = new ProgramSearchResponse();
    res.error = null;
    // res.selectionCriteria = { ...selectionCriteria };
    res.orderingCriteria = { ...orderingCriteria };
    res.pagingCriteria = { ...pagingCriteria };
    res.results = pageOfResults;
    res.total = allMatchingResults.length;
    return of(res);
  }
}

interface ProgramCard {
  id: string;
  totalCards: number;
}
