import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './store/root.reducer';
import { Effects } from './store/root.effects';
import { InitCardholderUsertypeEffects } from './store/init.cardholder.usertype/init.cardholder.usertype.effects';
import { InitEmlAdminUsertypeEffects } from './store/init.emladmin.usertype/init.emladmin.usertype.effects';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app.component';
import { ErrorComponent } from './components/error.component';

import { db, programPermissionsMapper } from './services/authorisation.data';
import { AuthorisedRouteGuard } from './guards/authorised-route.guard';
import { MsalGuard } from './guards/msal.guard';
import { SharedServicesModule } from '../protected/shared-services-module/shared-services.module';
import { BroadcastService } from './services/broadcast.service';
import { MsalService } from './services/msal.service';
import { AuthorisationService } from './services/authorisation.service';
import { MsalInterceptor } from './services/msal.interceptor';
import { EmlSdkInterceptor } from './services/emlsdk.interceptor';
import { PermissionService } from './services/permission.service';

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent
  ],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([Effects, InitCardholderUsertypeEffects, InitEmlAdminUsertypeEffects]),
    StoreDevtoolsModule.instrument({name: 'EML Portal'}),
    SharedServicesModule,
    AppRoutingModule,
  ],
  providers: [
    BroadcastService,
    MsalService,
    AuthorisationService,
    PermissionService,
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EmlSdkInterceptor, multi: true },
    { provide: 'AUTH_DB', useValue: db },
    { provide: 'PROGRAM_PERMISSIONS', useValue: programPermissionsMapper },
    MsalGuard,
    AuthorisedRouteGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
