import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from './guards/msal.guard';
import { ErrorComponent } from './components/error.component';

const routes: Routes = [
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'authredirect',
    loadChildren: '../public/auth-redirect-module/auth-redirect.module#AuthRedirectModule',
  },
  {
    path: 'loggedoff',
    loadChildren: '../public/logged-off-module/logged-off.module#LoggedOffModule',
  },
  {
    path: 'applications',
    loadChildren: '../protected/applications-reloadable-module/applications-reloadable.module#ApplicationsReloadableModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'batch-detail',
    loadChildren: '../protected/batch-detail-module/batch-detail.module#BatchDetailModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'card-search',
    loadChildren: '../protected/card-search-module/card-search.module#CardSearchModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'cardholder-search',
    loadChildren: '../protected/cardholder-search-module/cardholder-search.module#CardholderSearchModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'client-admin',
    loadChildren: '../protected/client-admin-module/client-admin.module#ClientAdminModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'create-cardholder',
    loadChildren: '../protected/create-cardholder-module/create-cardholder-module#CreateCardholderModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'dashboard',
    loadChildren: '../protected/dashboard-module/dashboard.module#DashboardModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'disbursements',
    loadChildren: '../protected/disbursements-module/disbursements.module#DisbursementsModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'exceptions',
    loadChildren: '../protected/exceptions-module/exceptions.module#ExceptionsModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'instant-gift',
    loadChildren: '../protected/instant-gift-module/instant-gift.module#InstantGiftModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'maintain-cards',
    loadChildren: '../protected/maintain-cards-module/maintain-cards.module#MaintainCardsModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'manage-cards',
    loadChildren: '../protected/manage-cards-module/manage-cards.module#ManageCardsModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'pending-applications',
    loadChildren: '../protected/pending-applications-module/pending-applications.module#PendingApplicationsModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'program-admin',
    loadChildren: '../protected/program-admin-module/program-admin.module#ProgramAdminModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'report-a-problem',
    loadChildren: '../protected/report-a-problem-module/report-a-problem.module#ReportAProblemModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'reporting',
    loadChildren: '../protected/reporting-module/reporting.module#ReportingModule',
  },
  {
    path: 'user-management',
    loadChildren: '../protected/user-management-module/user-management.module#UserManagementModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'program-user-management',
    loadChildren: '../protected/user-management-module/user-management.module#UserManagementModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'view-activity',
    loadChildren: '../protected/view-activity-module/view-activity.module#ViewActivityModule',
    canActivate: [MsalGuard]
  },
  {
    path: 'cardholder-accept',
    loadChildren: '../protected/confirm-and-accept-module/confirm-and-accept-module.module#ConfirmAndAcceptModule',
  },
  {
    path: 'user-accept',
    loadChildren: '../protected/user-accept-module/user-accept.module#UserAcceptModule',
  },
  {
    path: 'create-password',
    loadChildren: '../protected/dashboard-module/dashboard.module#DashboardModule'
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  }
];

let traceRouting = false;

if (localStorage.getItem('enable-route-tracing') === '1') {
  traceRouting = true;
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, enableTracing: traceRouting })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
