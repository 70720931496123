import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BatchStatusResponse } from '../models/batch-status-response';

@Injectable()
export class InstantGiftBatchStatusService {

  constructor(private http: HttpClient) { }

  getBatchTransferStatusList(programId: string, limit: number, continuation?: string): Observable<BatchStatusResponse> {
    let url = `${environment.eml_sdk_api_url}gift/${programId}/batch`;
    let params = new HttpParams();
    params = limit ? params.set('limit', '' + limit) : params;
    url += continuation ? `?continuation=${continuation}` : '';
    const options = { params };
    return this.http.get<BatchStatusResponse>(url, options);
  }

  getBatchById(programId: string, batchId: string): Observable<any> {
    const url = `${environment.eml_sdk_api_url}gift/${programId}/batch/${batchId}`;
    return this.http.get<any>(url);
  }
}
