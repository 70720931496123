import { Action } from '@ngrx/store';
import { SearchResponse } from '../../../protected/shared-services-module/models/search-v2/search-response';
import { ProgramAccountCard } from '../../../protected/shared-services-module/models/program-account-card';
import { ProgramSearchModel, Cardholder, PagedSearchResult, TransactionSearchModel, PagedSearchCriteria } from '../../../protected/shared-services-module/models';
import {Program} from '../../../protected/program-admin-module/models/program';

const FEATURE_NAME = 'init cardholder usertype';

export class ActionTypes {
  static readonly LOAD_CARDHOLDER = `[${FEATURE_NAME}] LOAD_CARDHOLDER`;
  static readonly LOAD_CARDHOLDER_FAILED = `[${FEATURE_NAME}] LOAD_CARDHOLDER_FAILED`;
  static readonly LOAD_CARDHOLDER_INACTIVE_REDIRECT = `[${FEATURE_NAME}] LOAD_CARDHOLDER_INACTIVE_REDIRECT`;
  static readonly CLEAR_CARDHOLDER_INACTIVE_REDIRECT = `[${FEATURE_NAME}] CLEAR_CARDHOLDER_INACTIVE_REDIRECT`;
  static readonly LOAD_CARDHOLDER_SUCCESSFUL = `[${FEATURE_NAME}] LOAD_CARDHOLDER_SUCCESSFUL`;
  static readonly LOAD_CARDHOLDER_ACCOUNTS = `[${FEATURE_NAME}] LOAD_CARDHOLDER_ACCOUNTS`;
  static readonly LOAD_CARDHOLDER_ACCOUNTS_FAILED = `[${FEATURE_NAME}] LOAD_CARDHOLDER_ACCOUNTS_FAILED`;
  static readonly LOAD_CARDHOLDER_ACCOUNTS_SUCCESSFUL = `[${FEATURE_NAME}] LOAD_CARDHOLDER_ACCOUNTS_SUCCESSFUL`;
  static readonly LOAD_CARDHOLDER_ACCOUNT_TRANSACTIONS = `[${FEATURE_NAME}] LOAD_CARDHOLDER_ACCOUNT_TRANSACTIONS`;
  static readonly LOAD_CARDHOLDER_ACCOUNT_TRANSACTIONS_FAILED = `[${FEATURE_NAME}] LOAD_CARDHOLDER_ACCOUNT_TRANSACTIONS_FAILED`;
  static readonly LOAD_CARDHOLDER_ACCOUNT_TRANSACTIONS_SUCCESSFUL = `[${FEATURE_NAME}] LOAD_CARDHOLDER_ACCOUNT_TRANSACTIONS_SUCCESSFUL`;
}

// ============================================================================

export interface LoadCardholderPayload {
  cardholder: Cardholder;
}

export class LoadCardholderAction {
  readonly type = ActionTypes.LOAD_CARDHOLDER;
  constructor(public readonly payload: LoadCardholderPayload = null) { }
}

// ============================================================================

export interface LoadCardholderFailedPayload {
  error: string;
}

export class LoadCardholderFailedAction {
  readonly type = ActionTypes.LOAD_CARDHOLDER_FAILED;
  constructor(public readonly payload: LoadCardholderFailedPayload = null) { }
}

// ============================================================================


export interface LoadCardholderInactiveRedirectPayload {
  cardholderInactiveRedirectUrl: string;
}

export class LoadCardholderInactiveRedirectAction {
  readonly type = ActionTypes.LOAD_CARDHOLDER_INACTIVE_REDIRECT;
  constructor(public readonly payload: LoadCardholderInactiveRedirectPayload = null) { }
}

// ============================================================================

export class ClearCardholderInactiveRedirectAction {
  readonly type = ActionTypes.CLEAR_CARDHOLDER_INACTIVE_REDIRECT;
  constructor(public readonly payload: any = null) { }
}

// ============================================================================

export interface LoadCardholderSuccessfulPayload {
  cardholder: Cardholder;
  program: Program;
}

export class LoadCardholderSuccessfulAction {
  readonly type = ActionTypes.LOAD_CARDHOLDER_SUCCESSFUL;
  constructor(public readonly payload: LoadCardholderSuccessfulPayload = null) { }
}

// ============================================================================

export interface LoadCardholderAccountsPayload {
  programId: string;
  cardholderId: string;
}

export class LoadCardholderAccountsAction implements Action {
  readonly type = ActionTypes.LOAD_CARDHOLDER_ACCOUNTS;
  constructor(readonly payload: LoadCardholderAccountsPayload = null) { }
}

// ============================================================================

export interface LoadCardholderAccountsFailedPayload {
  error: string;
}

export class LoadCardholderAccountsFailedAction implements Action {
  readonly type = ActionTypes.LOAD_CARDHOLDER_ACCOUNTS_FAILED;
  constructor(readonly payload: LoadCardholderAccountsFailedPayload = null) { }
}

// ============================================================================

export interface LoadCardholderAccountsSuccessfulPayload {
  programId: string;
  cardholderId: string;
  accounts: SearchResponse<ProgramAccountCard>;
}

export class LoadCardholderAccountsSuccessfulAction implements Action {
  readonly type = ActionTypes.LOAD_CARDHOLDER_ACCOUNTS_SUCCESSFUL;
  constructor(readonly payload: LoadCardholderAccountsSuccessfulPayload = null) { }
}

// ============================================================================

export interface LoadCardholderAccountTransactionsPayload {
  accountId: string;
  externalAccountId: string;
  criteria?: PagedSearchCriteria;
}

export class LoadCardholderAccountTransactionsAction implements Action {
  readonly type = ActionTypes.LOAD_CARDHOLDER_ACCOUNT_TRANSACTIONS;
  constructor(readonly payload: LoadCardholderAccountTransactionsPayload = null) { }
}

// ============================================================================

export interface LoadCardholderAccountTransactionsFailedPayload {
  error: string;
}

export class LoadCardholderAccountTransactionsFailedAction implements Action {
  readonly type = ActionTypes.LOAD_CARDHOLDER_ACCOUNT_TRANSACTIONS_FAILED;
  constructor(readonly payload: LoadCardholderAccountTransactionsFailedPayload = null) { }
}

// ============================================================================

export interface LoadCardholderAccountTransactionsSuccessfulPayload {
  accountId: string;
  externalAccountId: string;
  results: PagedSearchResult<TransactionSearchModel>;
}

export class LoadCardholderAccountTransactionsSuccessfulAction implements Action {
  readonly type = ActionTypes.LOAD_CARDHOLDER_ACCOUNT_TRANSACTIONS_SUCCESSFUL;
  constructor(readonly payload: LoadCardholderAccountTransactionsSuccessfulPayload = null) { }
}

// ============================================================================

export type Actions =
  | LoadCardholderAccountsAction
  | LoadCardholderAccountsFailedAction
  | LoadCardholderAccountsSuccessfulAction
  | LoadCardholderAccountTransactionsAction
  | LoadCardholderAccountTransactionsFailedAction
  | LoadCardholderAccountTransactionsSuccessfulAction
;
