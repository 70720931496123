export const environment = {
  aad_tennant_id: 'e09ff9bc-6129-4d88-be42-f65f0d4b0da2',
  aad_app_id: 'f238ddd3-7e8f-4931-9d53-ccd3a659b001',
  aad_tennant_name: 'emlportal.onmicrosoft.com',
  aad_signin_flow: 'B2C_1A_BETA_SIGNIN_MFA_ENABLED_WITH_EXCLUSIONS',
  aad_signup_flow: 'B2C_1A_signup_invitation',
  aad_forget_password_flow: 'B2C_1A_password_reset',
  eml_sdk_api_url: 'https://cmp-beta-api.emlpayments.com/mgmt/v1/',
  eml_sdk_api_key: '88042c2ebac643f0a2e25b70691801d2',
  eml_static_bucket_url: 'https://emlbetastaticbucket.blob.core.windows.net/images/',
  postLogoutRedirectUri: '/',
  redirectUri: '/',
  production: false,
  transaction_statement_month_limit: 18
};

// This is not for use in prod due to performance impact
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
