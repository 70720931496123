import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PagedSearchResult, PagedSearchCriteria, SortOrder } from '../models';
import { AccountModel } from '../models/account';
import { TransactionSearchModel } from '../models/transaction-search-model';
import {TransactionStatementQueryModel} from '../models/transaction-statement-query-model';
import * as querystring from 'querystring';
import {MigrateCardsRequestModel} from '../models/migrate-cards-request';

@Injectable()
export class AccountService {

  constructor(private http: HttpClient) { }

  getTransactions(accountId: string, criteria: PagedSearchCriteria = null, startDate: string = null, endDate: string = null)
      : Observable<PagedSearchResult<TransactionSearchModel>> {
    const url = `${environment.eml_sdk_api_url}accounts/${accountId}/transactions`;
    let params = new HttpParams();
    if (startDate && endDate) {
      params = startDate ? params.set('start_date', '' + startDate) : params;
      params = endDate ? params.set('end_date', '' + endDate) : params;
    }
    if (criteria) {
      params = criteria.pageNumber ? params.set('page_number', '' + criteria.pageNumber) : params;
      params = criteria.pageSize ? params.set('page_size', '' + criteria.pageSize) : params;
      params = criteria.sortByFieldName ? params.set('field', criteria.sortByFieldName) : params.set('field', 'occurred_at');
      params = criteria.sortDirection ? params.set('sort', criteria.sortDirection) : params.set('sort', SortOrder.Descending);
    }
    return this.http.get<PagedSearchResult<TransactionSearchModel>>(url, { params })
      .pipe(map((response: PagedSearchResult<TransactionSearchModel>) => {
        if (!response.sortByFieldName) {
          const rs = { ...response };
          rs.sortByFieldName = 'occurred_at';
          rs.sortDirection = SortOrder.Descending;
          return rs;
        }
        return response;
      }));
  }

  getAccountById(accountId: string): Observable<AccountModel> {
    const url = `${environment.eml_sdk_api_url}accounts/${accountId}`;
    return this.http.get<AccountModel>(url);
  }

  updateAccountById(accountId: string, accountData: AccountModel): Observable<AccountModel> {
    const url = `${environment.eml_sdk_api_url}accounts/${accountId}`;
    return this.http.put<AccountModel>(url, accountData);
  }

  cancelAccount(accountId: string, reason: string): Observable<AccountModel> {
    const url = `${environment.eml_sdk_api_url}accounts/${accountId}/cancel`;
    return this.http.post<AccountModel>(url, { reason });
  }

  optOutCard(accountId: string): Observable<any> {
    const url = `${environment.eml_sdk_api_url}accounts/${accountId}/cardrenewal/optout`;
    return this.http.get<any>(url);
  }

  downloadTransactionStatement(externalAccountId: string, data: TransactionStatementQueryModel): Observable<any> {
    let url = `${environment.eml_sdk_api_url}accounts/${data.external_account_id}/transactions/statement`;
    if (data && (data.start_date || data.end_date)) {
      url = `${url}?${querystring.stringify({
        external_account_id: data.external_account_id,
        start_date: data.start_date ? data.start_date.format('YYYY-MM-DD') : '',
        end_date:  data.end_date ? data.end_date.format('YYYY-MM-DD') : '',
        csv: data.csv}
        )}`;
    }
    return this.http.get<TransactionStatementQueryModel>(url, {responseType: 'blob' as 'json', observe: 'response' });
  }

  migrateCards(data: MigrateCardsRequestModel): Observable<any> {
    const url = `${environment.eml_sdk_api_url}programs/${data.program_id}/cardholders/${data.cardholder_id}/migration/create`;
    return this.http.post<AccountModel>(url, { target_program_id: data.target_program_id });
  }

  sendOTP(accountId: string, method: string, ipAddress: string) {
    const authenticateUrl = `${environment.eml_sdk_api_url}accounts/${accountId}/authenticate`;
    const authenticateBody = { applicationId: 'EML.Websites.CMP', ipAddress: ipAddress };

    const initiateUrl = `${environment.eml_sdk_api_url}accounts/${accountId}/initiate`;
    let initiateBody = { ipAddress: ipAddress, tokenId: '', communicationMethod: method };
 
    return this.http.post<any>(authenticateUrl, authenticateBody).pipe(
      switchMap(authenticate => {
        // Modify initiateBody with tokenId from authenticate response
        initiateBody.tokenId = authenticate.tokenId;
        return this.http.post<any>(initiateUrl, initiateBody);
      })
    );
  }
}