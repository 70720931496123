import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { ProgramPermissions } from '../models/program-permissions';

import { RootState } from '../store/root.state';
import * as fromSelectors from '../store/root.selectors';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {

  programPermissions: {} | ProgramPermissions;
  programPermissions$ = this.store.pipe(select(fromSelectors.programPermissionsSelector));

  constructor(private store: Store<RootState>) {
    this.programPermissions$.pipe().subscribe(pp => this.programPermissions = pp);
  }

  hasProgramPermission = (programId, featureKey) => {
    console.log('this is being called');
    console.log(this.programPermissions);
    return true;
  }

  hasViewFeatureAccess = (programId, featureKey) => {
    return this.programPermissions$.pipe().subscribe(pp => {
      if (pp && pp[programId] && pp[programId][featureKey]) {
        return pp[programId][featureKey] === 'V';
      }
      return false;
    });
  }

  hasEditFeatureAccess = (programId, featureKey) => {
    return this.programPermissions$.pipe().subscribe(pp => {
      if (pp && pp[programId] && pp[programId][featureKey]) {
        return pp[programId][featureKey] === 'E';
      }
      return false;
    });
  }
}
