import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BatchStatusResponse } from '../models/batch-status-response';

@Injectable()
export class UsersService {

  constructor(private http: HttpClient) { }

  searchForUsers(search) {
    const url = `${environment.eml_sdk_api_url}users/`;
    const body = {filter_criteria: search};
    return this.http.post(url, body);
  }

  resendUserEmail(user) {
    const url = `${environment.eml_sdk_api_url}users/${user.user_email_id}/resend`;
    return this.http.post(url, null);
  }

  updateExistingUser(user) {
    const url = `${environment.eml_sdk_api_url}users/${user.user_email_id}`;
    const body = user;
    return this.http.post(url, body);
  }
  
  removeExistedUser(user) {
    const url = `${environment.eml_sdk_api_url}users/${user.user_email_id}/delete`;
    return this.http.post(url, null);
  }

  testUserEmailExisted(email: string) {
    const url = `${environment.eml_sdk_api_url}users/${email}/check`;
    return this.http.get<any>(url);
  }
}
