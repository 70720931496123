export class ProgramSearchModel {
  id: string;
  name: string;
  type: string;
  status: string;
  currency: string;
  clientId?: string;
  company_id: string;
  active_card_count: number;
  inactive_card_count: number;
  total_cards: number;
  stored_value: number;
  disbursement_value: number;
  exception_count: number;
  pending_count: number;
  pending_value: number;
  disbursement_account_id?: string;
  account_details?: AccountDetails;
  sms_configured: boolean;
  sendgrid_template_id: string;
  active_le_card_count: number;
  active_me_card_count: number;
  cardholder_pending_application_count: number;
  cardholder_active_application_count: number;
  disable_transaction_statements: boolean;
  gift_program_claim_type: number;
  disbursement_allow_de_out: boolean;
}

export interface AccountDetails {
  bsb: string;
  account_no: string;
}
