import { Action } from '@ngrx/store';
import { FEATURE_NAME } from '../constants';

export class ActionTypes {
  static readonly INITIALIZE = `[${FEATURE_NAME}] INITIALIZE`;
  static readonly CANCEL_CARD = `[${FEATURE_NAME}] CANCEL_CARD`;
  static readonly CANCEL_CARD_FAILED = `[${FEATURE_NAME}] CANCEL_CARD_FAILED`;
  static readonly CANCEL_CARD_SUCCESSFUL = `[${FEATURE_NAME}] CANCEL_CARD_SUCCESSFUL`;
  static readonly RESET_CREATE_CARD = `[${FEATURE_NAME}] RESET_CREATE_CARD`;
  static readonly CREATE_CARD = `[${FEATURE_NAME}] CREATE_CARD`;
  static readonly CREATE_CARD_FAILED = `[${FEATURE_NAME}] CREATE_CARD_FAILED`;
  static readonly CREATE_CARD_SUCCESSFUL = `[${FEATURE_NAME}] CREATE_CARD_SUCCESSFUL`;
  static readonly UPDATE_CARDHOLDER = `[${FEATURE_NAME}] UPDATE_CARDHOLDER`;
  static readonly UPDATE_CARDHOLDER_FAILED = `[${FEATURE_NAME}] UPDATE_CARDHOLDER_FAILED`;
  static readonly UPDATE_CARDHOLDER_SUCCESSFUL = `[${FEATURE_NAME}] UPDATE_CARDHOLDER_SUCCESSFUL`;
  static readonly ACTIVATE_CARD = `[${FEATURE_NAME}] ACTIVATE_CARD`;
  static readonly ACTIVATE_CARD_FAILED = `[${FEATURE_NAME}] ACTIVATE_CARD_FAILED`;
  static readonly ACTIVATE_CARD_SUCCESSFUL = `[${FEATURE_NAME}] ACTIVATE_CARD_SUCCESSFUL`;
  static readonly RESET_ACTIVATE_CARD = `[${FEATURE_NAME}] RESET_ACTIVATE_CARD`;
  static readonly SEND_OTP = `[${FEATURE_NAME}] SEND_OTP`;
  static readonly SEND_OTP_FAILED = `[${FEATURE_NAME}] SEND_OTP_FAILED`;
  static readonly SEND_OTP_SUCCESSFUL = `[${FEATURE_NAME}] SEND_OTP_SUCCESSFUL`;
  static readonly ACTIVATE_RENEWAL_CARD_WITH_OTP = `[${FEATURE_NAME}] ACTIVATE_RENEWAL_CARD_WITH_OTP`;
  static readonly ACTIVATE_RENEWAL_CARD_WITH_OTP_FAILED = `[${FEATURE_NAME}] ACTIVATE_RENEWAL_CARD_WITH_OTP_FAILED`;
  static readonly ACTIVATE_RENEWAL_CARD_WITH_OTP_SUCCESSFUL = `[${FEATURE_NAME}] ACTIVATE_RENEWAL_CARD_WITH_OTP_SUCCESSFUL`;
}

// ============================================================================

export interface InitializePayload {
  programId: string;
  accountId: string;
}
export class InitializeAction implements Action {
  readonly type = ActionTypes.INITIALIZE;
  constructor(public readonly payload: InitializePayload = null) { }
}

// ============================================================================

export interface CancelCardPayload {
  accountId: string;
  reason: string;
}

export class CancelCardAction implements Action {
  readonly type = ActionTypes.CANCEL_CARD;
  constructor(public readonly payload: CancelCardPayload = null) { }
}

// ============================================================================

export interface CancelCardFailedPayload {
  errorMsg: string;
}

export class CancelCardFailedAction implements Action {
  readonly type = ActionTypes.CANCEL_CARD_FAILED;
  constructor(public readonly payload: any = null) { }
}

// ============================================================================

export interface CancelCardSuccessfulPayload {
  default: boolean;
}

export class CancelCardSuccessfulAction implements Action {
  readonly type = ActionTypes.CANCEL_CARD_SUCCESSFUL;
  constructor(public readonly payload: CancelCardSuccessfulPayload = null) { }
}

// ============================================================================

export class ResetCreateCardAction implements Action {
  readonly type = ActionTypes.RESET_CREATE_CARD;
  constructor(public readonly payload: any = null) { }
}

// ============================================================================

// export interface CreateCardPayload {
//   default: boolean;
// }

export class CreateCardAction implements Action {
  readonly type = ActionTypes.CREATE_CARD;
  constructor(public readonly payload: any = null) { }
}

// ============================================================================

export interface CreateCardFailedPayload {
  errorMsg: string;
}

export class CreateCardFailedAction implements Action {
  readonly type = ActionTypes.CREATE_CARD_FAILED;
  constructor(public readonly payload: any = null) { }
}

// ============================================================================

export interface CreateCardSuccessfulPayload {
  default: boolean;
}

export class CreateCardSuccessfulAction implements Action {
  readonly type = ActionTypes.CREATE_CARD_SUCCESSFUL;
  constructor(public readonly payload: CreateCardSuccessfulPayload = null) { }
}

// ============================================================================

export class UpdateCardholderAction implements Action {
  readonly type = ActionTypes.UPDATE_CARDHOLDER;
  constructor(public readonly payload: any = null) { }
}

// ============================================================================

export interface UpdateCardholderFailedPayload {
  errorMsg: string;
}

export class UpdateCardholderFailedAction implements Action {
  readonly type = ActionTypes.UPDATE_CARDHOLDER_FAILED;
  constructor(public readonly payload: any = null) { }
}

// ============================================================================

export interface UpdateCardholderSuccessfulPayload {
  default: boolean;
}

export class UpdateCardholderSuccessfulAction implements Action {
  readonly type = ActionTypes.UPDATE_CARDHOLDER_SUCCESSFUL;
  constructor(public readonly payload: UpdateCardholderSuccessfulPayload = null) { }
}

// ============================================================================

export interface ActivateCardPayload {
  programId: string;
  cardholderId: string;
  accountId: string;
  cardLast4: number;
}

export class ActivateCardAction implements Action {
  readonly type = ActionTypes.ACTIVATE_CARD;
  constructor(public readonly payload: ActivateCardPayload = null) { }
}

// ============================================================================

export interface ActivateCardFailedPayload {
  errorMsg: string;
}

export class ActivateCardFailedAction implements Action {
  readonly type = ActionTypes.ACTIVATE_CARD_FAILED;
  constructor(public readonly payload: any = null) { }
}

// ============================================================================

export interface ActivateCardSuccessfulPayload {
  default: boolean;
}

export class ActivateCardSuccessfulAction implements Action {
  readonly type = ActionTypes.ACTIVATE_CARD_SUCCESSFUL;
  constructor(public readonly payload: ActivateCardSuccessfulPayload = null) { }
}

// ============================================================================

export class ResetActivateCardAction implements Action {
  readonly type = ActionTypes.RESET_ACTIVATE_CARD;
  constructor(public readonly payload: any = null) { }
}

// ============================================================================

export class SendOTPAction implements Action {
  readonly type = ActionTypes.SEND_OTP;
  constructor(public readonly payload: any = null) { }
}

// ============================================================================

export class SendOTPFailedAction implements Action {
  readonly type = ActionTypes.SEND_OTP_FAILED;
  constructor(public readonly payload: any = null) { }
}

// ============================================================================

export class SendOTPSuccessfulAction implements Action {
  readonly type = ActionTypes.SEND_OTP_SUCCESSFUL;
  constructor(public readonly payload: any = null) { }
}

// ============================================================================

export interface ActivateRenewalCardPayload {
  programId: string;
  cardholderId: string;
  accountId: string;
  otp: number;
  operationId: string;
  ipAddress: string;
}

export class ActivateRenewalCardAction implements Action {
  readonly type = ActionTypes.ACTIVATE_RENEWAL_CARD_WITH_OTP;
  constructor(public readonly payload: ActivateRenewalCardPayload = null) { }
}

// ============================================================================

export class ActivateRenewalCardFailedAction implements Action {
  readonly type = ActionTypes.ACTIVATE_RENEWAL_CARD_WITH_OTP_FAILED;
  constructor(public readonly payload: any = null) { }
}

// ============================================================================

export class ActivateRenewalCardSuccessfulAction implements Action {
  readonly type = ActionTypes.ACTIVATE_RENEWAL_CARD_WITH_OTP_SUCCESSFUL;
  constructor(public readonly payload: any = null) { }
}

export type ActionsUnion = InitializeAction
  | CancelCardAction
  | CancelCardFailedAction
  | CancelCardSuccessfulAction
  | ResetCreateCardAction
  | CreateCardAction
  | CreateCardFailedAction
  | CreateCardSuccessfulAction
  | UpdateCardholderAction
  | UpdateCardholderFailedAction
  | UpdateCardholderSuccessfulAction
  | ActivateCardAction
  | ActivateCardFailedAction
  | ActivateCardSuccessfulAction
  | ResetActivateCardAction
  | SendOTPAction
  | SendOTPFailedAction
  | SendOTPSuccessfulAction
  | ActivateRenewalCardAction
  | ActivateRenewalCardFailedAction
  | ActivateRenewalCardSuccessfulAction;
