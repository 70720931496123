import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'
import { MsalService } from '../services/msal.service'
import {
  MSAL_ERROR_MESSAGE_BLOCK_TOKEN_REQUESTS,
  MSAL_ERROR_MESSAGE_INTERACTION_REQUIRED,
  MSAL_ERROR_MESSAGE_USER_LOGIN_ERROR
} from '../constants';

@Injectable()
export class MsalGuard implements CanActivate {

    constructor(private msalService: MsalService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      // Here we simply use the msalService to check the authentication to see if
      // we have a logged in user. This will allow us to view a route or not.
      if (this.msalService.processingRedirect) {
        console.log('MSAL is already processing a redirect, so wait for this to be completed.')
        return Promise.resolve(false)
      }

      return this.msalService.updateToken()
        .then(() => {
          return true
        })
        .catch((error) => {
          if (error.errorCode === MSAL_ERROR_MESSAGE_INTERACTION_REQUIRED) {
            try {
              this.msalService.acquireTokenRedirect(this.msalService.loginRequest) // redirect the user to login (acquire a new token)
            } catch (msalError) {
              console.log(`An error occurred inside the MSAL library while attempting to redirect the user to login. Error: ${msalError}.`)
              return false
            }
          } else if (error.errorCode === MSAL_ERROR_MESSAGE_BLOCK_TOKEN_REQUESTS) {
            try {
              this.msalService.login() // redirect the user to login (401)
            } catch (msalError) {
              console.log(`An error occurred inside the MSAL library while attempting to redirect the user to login. Error: ${msalError}.`)
              return false
            }
          } else {
            return false
          }
        })

    }
}
