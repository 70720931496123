import { Injectable } from '@angular/core';
import { CanLoad, Route } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState, routePermissionsSelector } from '../store';
import { Observable, of } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';
import { RoutePermission } from '../models/route-permission';

@Injectable({
  providedIn: 'root',
})
export class AuthorisedRouteGuard implements CanLoad {

  constructor(private store: Store<AppState>) { }

  canLoad(route: Route): Observable<boolean> {
    return this.store.pipe(
      select(routePermissionsSelector),
      first(),
      switchMap((permissions: RoutePermission[]) => {
        let result = false;
        if (permissions) {
          const matching = permissions.find(p => p.route === route.path);
          result = matching && (matching.permission !== '-' && matching.permission !== 'X');
        }
        return of(result);
      })
    );
  }

}
