import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BatchStatusResponse } from '../models/batch-status-response';

@Injectable()
export class ExceptionsService {

  constructor(private http: HttpClient) { }

  getExceptions(programId: string, limit: number = 5, continuation?: string): Observable<any> {
    let url = `${environment.eml_sdk_api_url}exceptions/${programId}`;
    let params = new HttpParams();
    params = limit ? params.set('limit', '' + limit) : params;
    url += continuation ? `?continuation=${continuation}` : '';
    const options = { params };
    return this.http.get<any>(url, options);
  }
}
