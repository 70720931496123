export class DisbursementTransfer {
  from: string;
  amount: number;
  description: string;
  destination_account: {
    bsb: number;
    account_number: number;
    account_name: string;
  };
}
