import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserPermissions } from '../models/user-permissions';
import { ResourcePermission } from './authorisation.data';
import { RoutePermission } from '../models/route-permission';

@Injectable({
  providedIn: 'root',
})
export class AuthorisationService {

  readonly standardOptions: {
    headers?: HttpHeaders;
    reportProgress?: boolean;
    params?: HttpParams;
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
    withCredentials?: boolean;
  };

  constructor(@Inject('AUTH_DB') private db, @Inject('PROGRAM_PERMISSIONS') private programPermissionsMapper, private http: HttpClient) {
    this.standardOptions = {
      headers: new HttpHeaders()
        .set('Ocp-Apim-Subscription-Key', environment.eml_sdk_api_key)
        .set('Ocp-Apim-Trace', 'true')
    };
  }

  getUserPermissions(): Observable<UserPermissions> {
    const url = `${environment.eml_sdk_api_url}user/permissions`;
    return this.http
      .get<UserPermissions>(
        url,
        { headers: this.standardOptions.headers }
      );
    // const profile = localStorage.getItem('user-permissions');
    // return profile
    //   ? of(JSON.parse(profile) as UserPermissions)
    //   : of({
    //     user_type:"Client",
    //     eml_role: null,
    //     client_id: 1,
    //     client_name: "Edge",
    //     client_role: "Administrator",
    //     permissions_list: [
    //        {
    //           name: "Test Company",
    //           program_id: "1",
    //           role: [
    //              "Administrator",
    //              "Approve"
    //           ]
    //        },
    //        {
    //           name: "Edge Instant Gift",
    //           program_id: "4",
    //           role: [
    //              "Approve"
    //           ]
    //        },
    //        {
    //           name: "SeventhBeam Instant Gift",
    //           program_id: "7",
    //           role: [
    //              "Maintain"
    //           ]
    //        },
    //        {
    //           name: "Edge Plastic Card",
    //           program_id: "10",
    //           role: [
    //              "No Access"
    //           ]
    //        },
    //        {
    //           name: "Acmi SalPak",
    //           program_id: "9",
    //           role: [
    //              "Administrator"
    //           ]
    //        },
    //        {
    //           name: "Badger Plastic Card",
    //           program_id: "11",
    //           role: [
    //              "Administrator"
    //           ]
    //        },
    //        {
    //           name: "Acmi Plastic Card",
    //           program_id: "12",
    //           role: [
    //              "Administrator"
    //           ]
    //        }
    //     ]
    // });
  }

  getRoutePermissions(userPermissions: UserPermissions): RoutePermission[] {
    const clientPermissionRoles: string[] = this.normaliseRoles(userPermissions.eml_role, userPermissions.client_role, userPermissions.user_type);
    const roleIdentifiers = this.db.roles.reduce((acc, dbRole) => {
      return clientPermissionRoles.some((cpr) => cpr === dbRole.name)
        ? [ ...acc, dbRole.id ]
        : acc;
    }, []);
    const routeIdentifiers = this.db.routes.map((dbRoute) => dbRoute.id);
    const permissionSet: ResourcePermission[] = this.db.permissions
      .filter((p) => p.resourceType === 'route')
      .filter((p) => roleIdentifiers.some((roleId) => roleId === p.roleId))
      .filter((p) => routeIdentifiers.some((routeId) => routeId === p.resourceId));
    const effectivePermissions: RoutePermission[] = this.db.routes.map((dbRoute) => {
      const routePermissions = permissionSet.filter((perm) => perm.resourceId === dbRoute.id);
      if (routePermissions.length === 0) {
        return { route: dbRoute.path, permission: null };
      } else if (routePermissions.some((rp) => rp.permission === 'R')) {
        return { route: dbRoute.path, permission: 'R' };
      } else if (routePermissions.some((rp) => rp.permission === 'W')) {
        return { route: dbRoute.path, permission: 'W' };
      } else if (routePermissions.some((rp) => rp.permission === 'X')) {
        return { route: dbRoute.path, permission: 'X' };
      } else {
        return { route: dbRoute.path, permission: null };
      }
    });
    return effectivePermissions;
  }

  getProgramPermissions(userPermissions: UserPermissions): any {
    const { permissions_list } = userPermissions;
    const programPermissions = {};
    permissions_list.forEach((program) => {
      programPermissions[program.program_id] = this.programPermissionsMapper(program.role[0]);
    });
    return programPermissions;
  }

  private normaliseRoles(clientPermissionsEmlRole: string, clientPermissionsClientRole: string, userType: string): string[] {
      // Normalise the eml role string
      let emlRole: string;
      switch ((clientPermissionsEmlRole || '').toLowerCase()) {
        case 'administrator':
          emlRole = 'eml-admin';
          break;
        case 'risk':
          emlRole = 'eml-risk';
          break;
        case 'client support':
          emlRole = 'eml-client-support';
          break;
        case 'maintain':
          emlRole = 'eml-maintain';
          break;
        case 'view':
          emlRole = 'eml-view';
          break;
        case 'no access':
          emlRole = 'eml-no-access';
          break;
        default:
          emlRole = null;
      }
      // Normalise the client role string
      let clientRole: string;
      switch ((clientPermissionsClientRole || '').toLowerCase()) {
        case 'administrator':
          clientRole = 'client-admin';
          break;
        case 'approve':
          clientRole = 'client-approve';
          break;
        case 'transact':
          clientRole = 'client-transact';
          break;
        case 'maintain':
          clientRole = 'client-maintain';
          break;
        case 'view':
          clientRole = 'client-view';
          break;
        case 'no access':
          clientRole = 'client-no-access';
          break;
        default:
          clientRole = null;
      }
      let cardholderRole = null;
      if ((userType || '').toLowerCase() === 'cardholder') {
        cardholderRole = 'cardholder';
      }
      const userRoles = [emlRole, clientRole, cardholderRole].filter(r => r !== null);
      return userRoles;
  }
}
