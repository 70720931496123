import { ClientSearchModel } from './client-search-model';
import { ClientSearchSelectionCriteria } from './client-search-selection-criteria';
import { OrderingCriteria } from './ordering-criteria';
import { PagingCriteria } from './paging-criteria';

export class ClientSearchResponse {
  selectionCriteria: ClientSearchSelectionCriteria;
  orderingCriteria: OrderingCriteria;
  pagingCriteria: PagingCriteria;
  results: ClientSearchModel[];
  error: string;
  total: number;
}
