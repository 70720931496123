import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { bootstrapErrorSelector } from '../store';
import * as fromSelectors from '../store/root.selectors';
import { Store, select } from '@ngrx/store';
import { RootState } from '../store/root.state';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  bootstrapError$ = this.store.pipe(select(bootstrapErrorSelector));

  userPermissions$ = this.store.pipe(select(fromSelectors.userPermissionsSelector));

  constructor(
    private store: Store<RootState>,
    private router: Router
  ) {}

  ngOnInit() {
    this.userPermissions$.subscribe((userPermissions: any) => {
      if (userPermissions) {
        this.router.navigate(['/']);
      }
    });
  }

}
