import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { BroadcastService } from '../services/broadcast.service';
import { MsalService } from '../services/msal.service';

import * as fromState from '../store/root.state';
import * as fromActions from '../store/root.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private broadcastService: BroadcastService,
    private msalService: MsalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<fromState.RootState>
  ) { }


  ngOnInit() {
    // Here upon starting the application, we check to see if there is a user logged in,
    // otherwise we will push the user to the login / signup process;
    if (!this.msalService.checkAuth() && !this.msalService.isProcessingRedirect()) {
      // Here, when a user does not have an auth at all, we need to check the request they
      // came in on to figure out where to push them. Most urls will simply take a user to
      // the Login screen but specific ones will push a user to the Signup Screen
      setTimeout(() => {
        const paths = window.location.href.split('/');

        if (paths && paths.find(item => item.includes('cardholder-accept'))) {
          const urlNoFragment = window.location.href.replace('/#/', '/');
          const idToken = new URL(urlNoFragment).searchParams.get('id_token');
          this.msalService.signup({
            id_token_hint: idToken
          });
        } else if (paths && paths.find(item => item.includes('user-accept'))) {
          const urlNoFragment = window.location.href.replace('/#/', '/');
          const idToken = new URL(urlNoFragment).searchParams.get('id_token');
          this.msalService.signup({
            id_token_hint: idToken
          });
        } else if (paths && paths.find(item => item.includes('create-password'))) {
          const urlNoFragment = window.location.href.replace('/#/', '/');
          const idToken = new URL(urlNoFragment).searchParams.get('id_token');
          this.msalService.signup({
            id_token_hint: idToken
          });
        } else {
          this.msalService.login();
        }
      }, 50);
    }

    // This here handles the case where we have a user and we aren't processing the
    // redirect (eg, a user has been logged in before and we still have their info in
    // the cache), Checking the validity of token attached to the auth here is handled
    // by the MSAL HTTP INTECEPTOR class
    if (this.msalService.checkAuth() && !this.msalService.didProcessedRedirect()) {
      setTimeout(() => {
        const paths = window.location.href.split('/');
        if (paths && paths.includes('cardholder-accept')) {
          this.msalService.setAuthorityToSignUp();
        } else if (paths && paths.includes('user-accept')) {
          this.msalService.setAuthorityToSignUp();
          this.store.dispatch(new fromActions.UserPermissionsAction());
          this.router.navigate(['/']);
        } else {
          this.store.dispatch(new fromActions.UserPermissionsAction());
        }
      }, 50);
    }

    // This broadcase message will be fired if a user was successfully signed in from
    // the Azure B2C service at the end of processing the redirect
    this.broadcastService.subscribe('msal:loginSuccess', () => {
      setTimeout(() => {
        if (window.location.href.includes('cardholder-accept')) {
          // User was redirected from signup page and landed back here
        } else if (window.location.href.includes('user-accept')) {
          // User was redirected from signup page and landed back here
          this.store.dispatch(new fromActions.UserPermissionsAction());
          this.router.navigate(['/']);
        } else if (window.location.href.includes('create-password')) {
          // User was redirected from signup page and landed back here
          this.store.dispatch(new fromActions.UserPermissionsAction());
          this.router.navigate(['/']);
        } else {
          // All other login success messages
          this.store.dispatch(new fromActions.UserPermissionsAction());
        }
      }, 50);
    });


    // setTimeout(() => {
    //   console.log('UPDATE TEH TOKEN SILENTLY');
    //   this.msalService.updateToken();
    // }, 500);
  }
}
