import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import {Observable, throwError, from, of} from 'rxjs';
import {MsalService} from './msal.service';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {
  MSAL_ERROR_MESSAGE_BLOCK_TOKEN_REQUESTS,
  MSAL_ERROR_MESSAGE_INTERACTION_REQUIRED,
  MSAL_ERROR_MESSAGE_USER_LOGIN_ERROR
} from '../constants';
import {el} from '@angular/platform-browser/testing/src/browser_util';

@Injectable()
export class MsalInterceptor implements HttpInterceptor {

  constructor(private msalService: MsalService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.addBearerTokenToRequest(req);

    return next.handle(req).pipe(
      catchError(
        err => {
          if (err instanceof HttpErrorResponse && (err.status === 401 || err.status === 403)) {
            return this.handleUnauthorisedError(err, req, next);
          }
          throw err;
        }
      )
    );
  }

  handleUnauthorisedError(err: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.msalService.updateToken()).pipe(
      mergeMap(() => {
        req = this.addBearerTokenToRequest(req);
        return next.handle(req);
      }),
      catchError((error) => {
        if (error.errorCode === MSAL_ERROR_MESSAGE_INTERACTION_REQUIRED) {
          this.msalService.acquireTokenRedirect(this.msalService.loginRequest); // redirect the user to login (acquire a new token)
        } else if (error.errorCode === MSAL_ERROR_MESSAGE_BLOCK_TOKEN_REQUESTS ||
          error.errorCode === MSAL_ERROR_MESSAGE_USER_LOGIN_ERROR) {
          this.msalService.login(); // redirect the user to login (401)
        }
        throw error;
      }));
  }

  addBearerTokenToRequest(req: HttpRequest<any>): HttpRequest<any> {
    const cachedToken = this.msalService.checkCacheforToken();
    if (cachedToken && cachedToken.accessToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${cachedToken.accessToken}`,
        }
      });
    }
    return req;
  }
}
