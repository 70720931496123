import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuditService {

  constructor(private http: HttpClient) { }

  getAudits(criteria): Observable<any> {
    const url = `${environment.eml_sdk_api_url}audit/search`;
    return this.http.post<any>(url, criteria);
  }
}
