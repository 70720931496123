import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BatchStatusResponse } from '../models/batch-status-response';

@Injectable()
export class ReportAProblemService {

  constructor(private http: HttpClient) { }

  reportLostOrStolenCard(): Observable<any> {
    const url = `${environment.eml_sdk_api_url}report-a-problem`;
    return this.http.post<any>(url, {});
  }

  reportDisputedTransaction(): Observable<any> {
    const url = `${environment.eml_sdk_api_url}report-a-disputed-transaction`;
    return this.http.post<any>(url, {});
  }

  reportDeclinedTransaction(): Observable<any> {
    const url = `${environment.eml_sdk_api_url}report-a-declined-transaction`;
    return this.http.post<any>(url, {});
  }
}
