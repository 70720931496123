import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../infrastructure-modules/material/material.module';

// import { PendingApplicationsMockService } from './services/pending-applications-mock.service';

import {
  AuditService,
  AccountService,
  CardholderService,
  ClientAdminService,
  ClientService,
  ExceptionsService,
  InstantGiftBatchStatusService,
  IpServiceService,
  ProgramService,
  ProgramAdminService,
  ReportAProblemService,
  ReportService,
  PendingApplicationsService,
  UsersService,
 } from './services';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: [],
  declarations: [],
  providers: [
    AuditService,
    AccountService,
    CardholderService,
    ClientAdminService,
    ClientService,
    ExceptionsService,
    InstantGiftBatchStatusService,
    IpServiceService,
    PendingApplicationsService,
    ProgramService,
    ProgramAdminService,
    ReportAProblemService,
    ReportService,
    UsersService,
  ]
})
export class SharedServicesModule { }
