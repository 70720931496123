import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { asyncScheduler } from 'rxjs';
import { environment } from '../../../../environments/environment';

export interface IEwayConfig {
  sharedPaymentUrl: string;
}

export type EwayModalPaymentCallback = (result: string, transactionId: string, errors: string) => void;

declare module eCrypt {
  function showModalPayment(config: IEwayConfig, resultCallback: EwayModalPaymentCallback);
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  calculateFees(amount: string, programId: string): Observable<CalculateFeesResponse> {
    const url = `${environment.eml_sdk_api_url}fees`;
    const body = { amount, programId };
    return this.http.post<CalculateFeesResponse>(url, body);
    // return of({}).pipe(delay(1500), switchMap(v => throwError(new HttpErrorResponse({status: 500, statusText: 'Internal Server Error'}), asyncScheduler)));
  }

  setupPayment(amount: string, account: string): Observable<SetupPaymentResponse> {
    const url = `${environment.eml_sdk_api_url}Payments`;
    const body = {amount, account};
    return this.http.post<SetupPaymentResponse>(url, body);
    // return of({}).pipe(delay(1500), switchMap(v => throwError(new HttpErrorResponse({status: 500, statusText: 'Internal Server Error'}), asyncScheduler)));
  }

  showModal(url: string): Observable<EwayModalResponse> {
    return new Observable((observer) => {
      eCrypt.showModalPayment({sharedPaymentUrl: url}, (result, transactionId, errors) => {
        observer.next({result, transactionId, errors} as EwayModalResponse);
        observer.complete();
      });
    });
  }

  confirmPayment(transactionId: string): Observable<any> {
    const url = `${environment.eml_sdk_api_url}payments/confirm`;
    const body = { transactionId };
    return this.http.post<ConfirmPaymentResponse>(url, body);
    // return of({}).pipe(delay(1500), switchMap(v => throwError(new HttpErrorResponse({status: 500, statusText: 'Internal Server Error'}), asyncScheduler)));
  }
}

export interface CalculateFeesResponse {
  total: string;
  fees: string;
}

export interface SetupPaymentResponse {
  access_token: string;
  fees: string;
  reference: string;
  total: string;
}

export interface EwayModalResponse {
  result: string;
  transactionId: string;
  errors: string;
}

export interface ConfirmPaymentResponse {
  payment_id: number;
  reference: number;
  created_on: string;
  updated_on: string;
  error: any;
  access_code: string;
  transaction_id: string;
  fees: number;
  amount: number;
  external_account_id: string;
  payment_status: string;
}
