export class AccountModel {
  id: string;
  phone: string;
  code: string;
  value: number;
  fname: string;
  lname: string;
  email: string;
  exp: string;
  company: string;
  status: string;
  program: string;
  batch: string;
  twilioSid: string;
}
