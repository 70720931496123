const ADMINISTRATOR = 'Administrator';
const APPROVE = 'Approve';
const TRANSACT = 'Transact';
const MAINTAIN = 'Maintain';
const VIEW = 'View';
const NO_ACCESS = 'No Access';

export const PROGRAM_ACCESS_ROLES_ARRAY = [
  ADMINISTRATOR,
  APPROVE,
  TRANSACT,
  MAINTAIN,
  VIEW,
  NO_ACCESS,
];

export const PROGRAM_ACCESS_ROLES = {
  'ADMINISTRATOR': ADMINISTRATOR,
  'APPROVE': APPROVE,
  'TRANSACT': TRANSACT,
  'MAINTAIN': MAINTAIN,
  'VIEW': VIEW,
  'NO_ACCESS': NO_ACCESS,
};

export const PROGRAM_ACCESS_ROLES_DROPDOWN = [
  {id: 0, value: 0, name: ADMINISTRATOR},
  {id: 1, value: 1, name: APPROVE},
  {id: 2, value: 2, name: TRANSACT},
  {id: 3, value: 3, name: MAINTAIN},
  {id: 4, value: 4, name: VIEW},
  {id: 5, value: 5, name: NO_ACCESS},
];
