import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

interface Client {
  cliend_id: string;
  name: string;
  status: string;
}

@Injectable()
export class ClientService {

  constructor(private http: HttpClient) { }

  getClientPrograms(clientId: string) {
    const url = `${environment.eml_sdk_api_url}clients/${clientId}/programs`;
    return this.http.get<any>(url);
  }

  getClientProgramsById(clientId: string, query: any) {
    const url = `${environment.eml_sdk_api_url}clients/${clientId}/programs`;
    return this.http.get<any>(url);
  }

  getClientProgramByProgramId(clientId: string, programId: any) {
    const url = `${environment.eml_sdk_api_url}clients/${clientId}/programs/${programId}`;
    return this.http.get<any>(url);
  }

  searchClients(searchCriteria?: any) {
    const url = `${environment.eml_sdk_api_url}clients/search`;
    const body = {
      searchCriteria
    };
    return this.http.post<any>(url, body);
  }

  createClient(client: Client) {
    const url = `${environment.eml_sdk_api_url}clients`;
    const body = {
      ...client
    };
    return this.http.post<any>(url, body);
  }

  createClientProgram(clientId: string, programType: string, programData: any) {
    const url = `${environment.eml_sdk_api_url}clients/${clientId}/programs?type=${programType}`;
    const body = {
      ...programData
    };
    return this.http.post<any>(url, body);
  }

  updateClientProgram(clientId: string, programId: string, programType: string, programData: any) {
    const url = `${environment.eml_sdk_api_url}clients/${clientId}/programs/${programId}?type=${programType}`;
    const body = {
      ...programData
    };
    return this.http.patch<any>(url, body);
  }

  updateClient(client_id: string, update_data: any) {
    const url = `${environment.eml_sdk_api_url}clients/${client_id}`;
    const body = {
      ...update_data
    };
    return this.http.put<any>(url, body);
  }

  refreshClientToken(id: string) {
    const url = `${environment.eml_sdk_api_url}clients/${id}/token/refresh`;
    const body = {};
    return this.http.post<any>(url, body);
  }
}
