import { Action } from '@ngrx/store';

const FEATURE_NAME = 'INIT EML ADMIN USERTYPE';

export class ActionTypes {
  static readonly LOAD_CLIENTS = `[${FEATURE_NAME}] LOAD_CLIENTS`;
  static readonly LOAD_CLIENTS_FAILED = `[${FEATURE_NAME}] LOAD_CLIENTS_FAILED`;
  static readonly LOAD_CLIENTS_SUCCESSFUL = `[${FEATURE_NAME}] LOAD_CLIENTS_SUCCESSFUL`;
}

// ============================================================================

export class LoadClientsAction {
  readonly type = ActionTypes.LOAD_CLIENTS;
  constructor(public readonly payload: any = null) { }
}

// ============================================================================

export interface LoadClientsFailedPayload {
  errorMsg: string;
}

export class LoadClientsFailedAction {
  readonly type = ActionTypes.LOAD_CLIENTS_FAILED;
  constructor(public readonly payload: LoadClientsFailedPayload = null) { }
}

// ============================================================================

export interface LoadClientsSuccessfulPayload {
  clients: any[];
}

export class LoadClientsSuccessfulAction {
  readonly type = ActionTypes.LOAD_CLIENTS_SUCCESSFUL;
  constructor(public readonly payload: any = null) { }
}

// ============================================================================

export type Actions =
  | LoadClientsAction
  | LoadClientsFailedAction
  | LoadClientsSuccessfulAction
;
