import { Action } from '@ngrx/store';
import { ProgramSearchModel, Cardholder } from '../../protected/shared-services-module/models';
import { UserPermissions } from '../models/user-permissions';
import { RoutePermission } from '../models/route-permission';

const FEATURE_NAME = 'root';

export class ActionTypes {
  static readonly LOAD_USER_PROGRAMS = `[${FEATURE_NAME}] LOAD_USER_PROGRAMS`;
  static readonly LOAD_USER_PROGRAMS_FAILED = `[${FEATURE_NAME}] LOAD_USER_PROGRAMS_FAILED`;
  static readonly LOAD_USER_PROGRAMS_SUCCESSFUL = `[${FEATURE_NAME}] LOAD_USER_PROGRAMS_SUCCESSFUL`;

  static readonly LOGIN = `[${FEATURE_NAME}] LOGIN`;
  static readonly LOGIN_FAILED = `[${FEATURE_NAME}] LOGIN_FAILED`;
  static readonly LOGIN_SUCCEEDED = `[${FEATURE_NAME}] LOGIN_SUCCEEDED`;
  static readonly LOGOUT = `[${FEATURE_NAME}] LOGOUT`;
  static readonly LOGOUT_FAILED = `[${FEATURE_NAME}] LOGOUT_FAILED`;
  static readonly LOGOUT_SUCCEEDED = `[${FEATURE_NAME}] LOGOUT_SUCCEEDED`;
  static readonly USER_PERMISSIONS = `[${FEATURE_NAME}] USER_PERMISSIONS`;
  static readonly USER_PERMISSIONS_FAILED = `[${FEATURE_NAME}] USER_PERMISSIONS_FAILED`;
  static readonly USER_PERMISSIONS_SUCCEEDED = `[${FEATURE_NAME}] USER_PERMISSIONS_SUCCEEDED`;
  static readonly SIDE_MENU_COLLAPSE = `[${FEATURE_NAME}] SIDE_MENU_COLLAPSE`;
  static readonly SIDE_MENU_EXPAND = `[${FEATURE_NAME}] SIDE_MENU_EXPAND`;
  static readonly SET_SIDEBAR_USER_PROGRAM = `[${FEATURE_NAME}] SET_SIDEBAR_USER_PROGRAM`;
  static readonly CLEAR_SIDEBAR_USER_PROGRAM = `[${FEATURE_NAME}] CLEAR_SIDEBAR_USER_PROGRAM`;
  static readonly SET_SIDEBAR_USER_CLIENT = `[${FEATURE_NAME}] SET_SIDEBAR_USER_CLIENT`;
  static readonly CLEAR_SIDEBAR_USER_CLIENT = `[${FEATURE_NAME}] CLEAR_SIDEBAR_USER_CLIENT`;

  static readonly INIT_CARDHOLDER_USERTYPE = `[${FEATURE_NAME}] INIT_CARDHOLDER_USERTYPE`;
  static readonly INIT_EML_ADMIN_USERTYPE = `[${FEATURE_NAME}] INIT_EML_ADMIN_USERTYPE`;
}

// ============================================================================

export class LoadUserProgramsAction implements Action {
  readonly type = ActionTypes.LOAD_USER_PROGRAMS;
  constructor(public payload: any = null) { }
}
// ============================================================================

export class LoadUserProgramsFailedAction implements Action {
  readonly type = ActionTypes.LOAD_USER_PROGRAMS_FAILED;
  constructor(public payload: any = null) { }
}

// ============================================================================

export interface LoadUserProgramsSuccessfulPayload {
  programs: ProgramSearchModel[];
}
export class LoadUserProgramsSuccessfulAction implements Action {
  readonly type = ActionTypes.LOAD_USER_PROGRAMS_SUCCESSFUL;
  constructor(public payload: LoadUserProgramsSuccessfulPayload = null) { }
}

// ============================================================================



export class LoginAction implements Action {
    readonly type = ActionTypes.LOGIN;
    constructor(public payload: any) { }
}

// ============================================================================

export class LoginFailedAction implements Action {
    readonly type = ActionTypes.LOGIN_FAILED;
    constructor(public payload: any) { }
}

// ============================================================================

export class LoginSucceededAction implements Action {
    readonly type = ActionTypes.LOGIN_SUCCEEDED;
    constructor(public payload: any) { }
}

// ============================================================================

export class LogoutAction implements Action {
  readonly type = ActionTypes.LOGOUT;
  constructor(public payload: any = null) { }
}

// ============================================================================

export class LogoutFailedAction implements Action {
  readonly type = ActionTypes.LOGOUT_FAILED;
  constructor(public payload: any) { }
}

// ============================================================================

export class LogoutSucceededAction implements Action {
  readonly type = ActionTypes.LOGOUT_SUCCEEDED;
  constructor(public payload: any = null) { }
}

// ============================================================================

export class UserPermissionsAction implements Action {
  readonly type = ActionTypes.USER_PERMISSIONS;
  constructor(public payload: any = null) { }
}

// ============================================================================

export class UserPermissionsFailedAction implements Action {
  readonly type = ActionTypes.USER_PERMISSIONS_FAILED;
  constructor(public payload: any = null) { }
}

// ============================================================================

export interface UserPermissionsSucceededPayload {
  clientPermissions: UserPermissions;
  routePermissions: RoutePermission[];
  programPermissions: any;
}

export class UserPermissionsSucceededAction implements Action {
  readonly type = ActionTypes.USER_PERMISSIONS_SUCCEEDED;
  constructor(public payload: UserPermissionsSucceededPayload = null) { }
}

// ============================================================================

export class SideMenuCollapseAction implements Action {
  readonly type = ActionTypes.SIDE_MENU_COLLAPSE;
  constructor(public payload: any = null) { }
}

export class SideMenuExpandAction implements Action {
  readonly type = ActionTypes.SIDE_MENU_EXPAND;
  constructor(public payload: any = null) { }
}

// ============================================================================

export class SetSidebarProgramAction implements Action {
  readonly type = ActionTypes.SET_SIDEBAR_USER_PROGRAM;
  constructor(public payload: any = null) { }
}

// ============================================================================

export class ClearSidebarProgramAction implements Action {
  readonly type = ActionTypes.CLEAR_SIDEBAR_USER_PROGRAM;
  constructor(public payload: any = null) { }
}

// ============================================================================

export class SetSidebarClientAction implements Action {
  readonly type = ActionTypes.SET_SIDEBAR_USER_CLIENT;
  constructor(public payload: any = null) { }
}

// ============================================================================

export class ClearSidebarClientAction implements Action {
  readonly type = ActionTypes.CLEAR_SIDEBAR_USER_CLIENT;
  constructor(public payload: any = null) { }
}

// ============================================================================

export class InitCardholderUsertypeAction implements Action {
  readonly type = ActionTypes.INIT_CARDHOLDER_USERTYPE;
  constructor(public payload: any = null) { }
}

// ============================================================================

export class InitEmlAdminUsertypeAction implements Action {
  readonly type = ActionTypes.INIT_EML_ADMIN_USERTYPE;
  constructor(public payload: any = null) { }
}

// ============================================================================

export type ActionsUnion =
    | LoginAction
    | LoginFailedAction
    | LoginSucceededAction
    | LogoutAction
    | LogoutFailedAction
    | LogoutSucceededAction
    | UserPermissionsAction
    | UserPermissionsFailedAction
    | UserPermissionsSucceededAction
    | SideMenuCollapseAction
    | SideMenuExpandAction
    | LoadUserProgramsAction
    | LoadUserProgramsFailedAction
    | LoadUserProgramsSuccessfulAction
    | SetSidebarProgramAction
    | ClearSidebarProgramAction
    | SetSidebarClientAction
    | ClearSidebarClientAction
    | InitCardholderUsertypeAction
    | InitEmlAdminUsertypeAction;
