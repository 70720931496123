import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

import {
  ApplicationExceptionSearchModel,
  DeliveryExceptionSearchModel,
  ForwardOnlySearchCriteria,
  ForwardOnlySearchResult,
  PagedSearchResult,
  PendingActivationSearchModel,
  PendingApplicationSearchModel,
  ProgramSearchModel,
  SortOrder,
} from '../models';

@Injectable()
export class PendingApplicationsService {

  constructor(private http: HttpClient) { }

  private search(url: string, criteria: ForwardOnlySearchCriteria, initialParams?: HttpParams): Observable<any> {
    const params = this.makeParamsFromCriteria(criteria, initialParams);
    return this.http.get(url, { params });
  }

  private retrieve(url: string, criteria: ForwardOnlySearchCriteria, initialParams?: HttpParams): Observable<any> {
    let body = {};
    const params = this.makeParamsFromCriteria(criteria, initialParams); 
    if(criteria.continuationToken){
      body = {next_page_token: criteria.continuationToken};
    }
    return this.http.post(url, body, { params });
  }

  private makeParamsFromCriteria(criteria: ForwardOnlySearchCriteria, initialParams?: HttpParams): HttpParams {
    let params = initialParams ? initialParams : new HttpParams();
    if (criteria && criteria.limit) {
      params = params.set('limit', '' + criteria.limit);
    }
    if (criteria && criteria.sortByFieldName) {
      params = params.set('field', '' + criteria.sortByFieldName);
    }
    if (criteria && criteria.sortDirection) {
      params = params.set('sort', '' + criteria.sortDirection);
    }
    if (criteria && criteria.batchId) {
      params = params.set('batchId', '' + criteria.batchId);
    }
    return params;
  }

  findProgram(config: any): Observable<ProgramSearchModel> {
    const { programId, clientId } = config;
    let params = new HttpParams();
    if (clientId) {
      params = params.set('client_id', clientId);
    }
    const url = `${environment.eml_sdk_api_url}user/programs`;
    return this.http.get<PagedSearchResult<ProgramSearchModel>>(url, { params }).pipe(
      map((response: PagedSearchResult<ProgramSearchModel>) => {
        return response.results.find(p => p.id === programId);
      })
    );
  }

  // ==============
  // SMS SERVICES
  // ==============
  resendPendingInstantGiftSMS(accountId: string, programId: string): Observable<any> {
      const url = `${environment.eml_sdk_api_url}accounts/${accountId}/resendsms`;
      const body = {programId: programId};
      return this.http.post(url, body);
  }

  cancelPendingInstantGiftSMS(accountId: string, programId: string): Observable<any> {
      const url = `${environment.eml_sdk_api_url}accounts/${accountId}/cancel`;
      const body = {programId: programId};
      return this.http.post(url, body);
  }

  resendPendingInstantGiftEmail(programId: string, cardholderId: string): Observable<any> {
      const url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/${cardholderId}/email`;
      const body = {};
      return this.http.post(url, body);
  }

  // ==============
  // NON-RELOADABLE
  // ==============
  searchForDeliveryExceptions(programId: string, criteria: ForwardOnlySearchCriteria)
      : Observable<ForwardOnlySearchResult<DeliveryExceptionSearchModel>> {
    let url = `${environment.eml_sdk_api_url}programs/${programId}/pendingApplications`;
    if (criteria.continuationToken) {
      url += '?next_page_token=' + encodeURIComponent(criteria.continuationToken);
    }
    const params = new HttpParams().set('type', 'delivery');
    return this.search(url, criteria, params).pipe(
      map((response) => {
        const rs = new ForwardOnlySearchResult<DeliveryExceptionSearchModel>();
        if (response.delivery_exceptions) {
          rs.continuationToken = response.delivery_exceptions.next_page_token;
          rs.results = response.delivery_exceptions.results;
        } else {
          rs.continuationToken = null;
          rs.results = [];
        }
        rs.limit = response.paging.limit ||  5;
        rs.sortByFieldName = response.ordering.field === 'fname' || response.ordering.field === 'lname' ? 'name' : response.ordering.field;
        rs.sortDirection = response.ordering.sort ? <SortOrder>response.ordering.sort.toUpperCase() : SortOrder.Ascending;
        return rs;
      })
    );
  }

  searchForPendingActivations(programId: string, criteria: ForwardOnlySearchCriteria)
      : Observable<ForwardOnlySearchResult<PendingActivationSearchModel>> {
    let url = `${environment.eml_sdk_api_url}programs/${programId}/pendingApplications`;
    const params = new HttpParams().set('type', 'activations');
    return this.retrieve(url, criteria, params).pipe(
      map((response) => {
        const rs = new ForwardOnlySearchResult<PendingActivationSearchModel>();
        if (response.pending_activations) {
          rs.continuationToken = response.pending_activations.next_page_token;
          rs.results = response.pending_activations.results;
        } else {
          rs.continuationToken = null;
          rs.results = [];
        }
        rs.limit = response.paging.limit ||  5;
        rs.sortByFieldName = response.ordering.field === 'fname' || response.ordering.field === 'lname' ? 'name' : response.ordering.field;
        rs.sortDirection = response.ordering.sort ? <SortOrder>response.ordering.sort.toUpperCase() : SortOrder.Ascending;
        return rs;
      })
    );
  }

  // ==========
  // RELOADABLE
  // ==========

  searchForApplicationExceptions(programId: string, criteria: ForwardOnlySearchCriteria)
      : Observable<ForwardOnlySearchResult<ApplicationExceptionSearchModel>> {
    let url = `${environment.eml_sdk_api_url}programs/${programId}/pendingApplications`;
    if (criteria.continuationToken) {
      url += '?next_page_token=' + encodeURIComponent(criteria.continuationToken);
    }
    const params = new HttpParams().set('type', 'exceptions');
    return this.search(url, criteria, params).pipe(
      map((response) => {
        const rs = new ForwardOnlySearchResult<ApplicationExceptionSearchModel>();
        if (response.application_exceptions) {
          rs.continuationToken = response.application_exceptions.next_page_token;
          rs.results = response.application_exceptions.results;
        } else {
          rs.continuationToken = null;
          rs.results = [];
        }
        rs.limit = response.paging.limit ||  5;
        rs.sortByFieldName = response.ordering.field === 'fname' || response.ordering.field === 'lname' ? 'name' : response.ordering.field;
        rs.sortDirection = response.ordering.sort ? <SortOrder>response.ordering.sort.toUpperCase() : SortOrder.Ascending;
        return rs;
      })
    );
  }

  searchForPendingApplications(programId: string, criteria: ForwardOnlySearchCriteria)
      : Observable<ForwardOnlySearchResult<PendingApplicationSearchModel>> {
    let url = `${environment.eml_sdk_api_url}programs/${programId}/pendingApplications`;
    if (criteria.continuationToken) {
      url += '?next_page_token=' + encodeURIComponent(criteria.continuationToken);
    }
    const params = new HttpParams().set('type', 'applications');
    return this.search(url, criteria, params).pipe(
      map((response) => {
        const rs = new ForwardOnlySearchResult<PendingApplicationSearchModel>();
        if (response.pending_applications) {
          rs.continuationToken = response.pending_applications.next_page_token;
          rs.results = response.pending_applications.results;
        } else {
          rs.continuationToken = null;
          rs.results = [];
        }
        rs.limit = response.paging.limit ||  5;
        rs.sortByFieldName = response.ordering.field === 'fname' || response.ordering.field === 'lname' ? 'name' : response.ordering.field;
        rs.sortDirection = response.ordering.sort ? <SortOrder>response.ordering.sort.toUpperCase() : SortOrder.Ascending;
        return rs;
      })
    );
  }

  resendAllPendingApplicationEmails(programId: number): Observable<boolean> {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/pendingapplications/resendall`;
    return this.http.post<boolean>(url, {});
  }
}
