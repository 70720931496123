import { Injectable } from '@angular/core';  
import { HttpClient  } from '@angular/common/http';  
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
  
@Injectable({  
  providedIn: 'root'  
})  
export class IpServiceService {  
  ipLookupUrl: string = 'https://jsonip.com/';
  
  constructor(private http: HttpClient) { }  

  getIPAddress() {  
    return this.http.get(this.ipLookupUrl).pipe(
        catchError(() => {
          // Assign a default IP address in case of an error
          const defaultIp = { ip: '127.0.0.1' };
          return of(defaultIp);
        })
    );
  }
}  