import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, tap, map } from 'rxjs/operators';
import { AuthorisationService } from '../services/authorisation.service';
import { MsalService } from '../services/msal.service';
import * as fromActions from './root.actions';
import { UserPermissions } from '../models/user-permissions';
import { PagedSearchResult, ProgramSearchModel, Cardholder } from '../../protected/shared-services-module/models';
import { ProgramService, CardholderService } from '../../protected/shared-services-module/services';
import { errorMapper } from '../../protected/shared-services-module/services/error-mapper';
import { USER_TYPES } from '../../protected/shared-constants/user-types';


@Injectable()
export class Effects {

  constructor(
    private actions: Actions,
    private authService: AuthorisationService,
    private msalService: MsalService,
    private programService: ProgramService,
    private cardholderService: CardholderService) { }

  @Effect()
  loadUserPrograms$ = this.actions.pipe(
    ofType(fromActions.ActionTypes.LOAD_USER_PROGRAMS),
    switchMap((action: fromActions.LoadUserProgramsAction) => {
      const criteria = (action.payload) ? action.payload.criteria : null;
      const clientId = (action.payload) ? action.payload.clientId : null;
      return this.programService.getUserProgramsBasic(criteria, clientId).pipe(
        map((response: PagedSearchResult<ProgramSearchModel>) => {
          return new fromActions.LoadUserProgramsSuccessfulAction({ programs: response.results });
        }),
        catchError((error) => {
          return of(new fromActions.LoadUserProgramsFailedAction(errorMapper(error)));
        })
      );
    })
  );

  @Effect()
  userPermissions: Observable<any> = this.actions.pipe(
    ofType(fromActions.ActionTypes.USER_PERMISSIONS),
    switchMap((action: fromActions.UserPermissionsAction) => {
      return this.authService.getUserPermissions().pipe(
        switchMap((cp: UserPermissions) => {
          const rp = this.authService.getRoutePermissions(cp)
            .map((item) => item.route !== 'client-admin' ? item : { ...item, permission: 'W'});
          const pp = this.authService.getProgramPermissions(cp);
          return of(new fromActions.UserPermissionsSucceededAction({
            clientPermissions: cp,
            routePermissions: rp,
            programPermissions: pp
          }));
        }),
        catchError((error) => {
          return of(new fromActions.UserPermissionsFailedAction(errorMapper(error)));
        })
      );
    })
  );

  // @Effect()
  // userPermissions: Observable<any> = this.actions.pipe(
  //   ofType(fromActions.ActionTypes.USER_PERMISSIONS),
  //   switchMap((action: fromActions.UserPermissionsAction) => {
  //     return this.authService.getUserPermissions().pipe(
  //       switchMap((cp: UserPermissions) => {
  //         console.log('what is cp?');
  //         console.log(cp);
  //         const rp = this.authService.getRoutePermissions(cp)
  //           .map((item) => item.route !== 'client-admin' ? item : { ...item, permission: 'W'});
  //         // cp.user_type = USER_TYPES.EML_ADMIN; /* Uncomment this line to override the user type for testing */
  //         return of(new fromActions.UserPermissionsSucceededAction({
  //           clientPermissions: cp,
  //           routePermissions: rp
  //         }));
  //       }),
  //       catchError((error) => {
  //         console.log('what the fuck is hte error in here?');
  //         console.log(error);
  //         return of(new fromActions.UserPermissionsFailedAction(errorMapper(error)));
  //       })
  //     );
  //   })
  // );

  @Effect()
  userPermissionsSuccessful: Observable<any> = this.actions.pipe(
    ofType(fromActions.ActionTypes.USER_PERMISSIONS_SUCCEEDED),
    switchMap((action: fromActions.UserPermissionsSucceededAction) => {
      if (action.payload.clientPermissions.user_type === USER_TYPES.CLIENT_USER) {
        return of(new fromActions.LoadUserProgramsAction());
      } else if (action.payload.clientPermissions.user_type === USER_TYPES.CARDHOLDER) {
        return of(new fromActions.InitCardholderUsertypeAction());
      } else if (action.payload.clientPermissions.user_type === USER_TYPES.EML_USER) {
        return of(new fromActions.InitEmlAdminUsertypeAction());
      }
    })
  );

  @Effect({dispatch: false})
  logout: Observable<any> = this.actions.pipe(
    ofType(fromActions.ActionTypes.LOGOUT),
    tap(() => {
      this.msalService._logout();
    })
  );
}
