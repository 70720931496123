export class PendingMigration {
  id: string;
  card_holder_id: string;
  client_account_key: string;
  card_holder_name: string;
  requested_by_user_email_id: string;
  requested_by_user_name: string;
  approved_by_user_email_id: string;
  rejected_by_user_email_id: string;
  cancelled_by_user_email_id: string;
  source_program_id: number;
  target_program_id: number;
  source_program_name: string;
  target_program_name: string;
  migration_status: string;
  migration_status_id: number;
  requested_date: string;
  cancel: { state: string };
  reject: { state: string };
  approve: { state: string };
  view: { state: string };
}
