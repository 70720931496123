import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Cardholder } from '../models';
import { of, Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { SearchResponse } from '../../shared-services-module/models/search-v2/search-response';
import { ProgramAccountCard } from '../models/program-account-card';
import {Program} from '../../program-admin-module/models/program';

@Injectable()
export class CardholderService {

  private readonly standardOptions: {
    headers?: HttpHeaders;
    reportProgress?: boolean;
    params?: HttpParams;
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
    withCredentials?: boolean;
  };

  constructor(private http: HttpClient) {
    this.standardOptions = {
      headers: new HttpHeaders()
        .set('Ocp-Apim-Subscription-Key', environment.eml_sdk_api_key)
        .set('Ocp-Apim-Trace', 'true')
    };
  }

  fetchCardholder(programId: string, cardholderId: string) {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/${cardholderId}`;
    return this.http.get<Cardholder>(url);
  }

  loadCardholder(): Observable<Cardholder> {
    const url = `${environment.eml_sdk_api_url}cardholders`;
    return this.http.get<Cardholder>(url);
  }

  loadProgram(programId: string): Observable<Program> {
    const url = `${environment.eml_sdk_api_url}programs/${programId}`;
    return this.http.get<Program>(url).pipe();
  }

  fetchCardholderAccounts(programId: string, cardholderId: string, criteria?: any) {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/${cardholderId}/accounts/search`;
    return this.http.post<SearchResponse<ProgramAccountCard>>(url, criteria).pipe(
      map((response: SearchResponse<ProgramAccountCard>) => {
        if (response === null) {
          return {
            filter: null,
            ordering: null,
            paging: {
              page_number: 1,
              page_size: 5,
              total_items: 0,
              total_pages: 1,
            },
            results: []
          };
        }
        return response;
      }
    ));
  }

  activateCard(programId: string, cardholderId: string, accountId: string, body: any) {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/${cardholderId}/accounts/${accountId}/activate`;
    return this.http.post<any>(url, body);
  }

  activateRenewalCard(accountId: string, body: any) {
    const url = `${environment.eml_sdk_api_url}accounts/${accountId}/activate`;
    return this.http.post<any>(url, body);
  }

  createCardholder(programId: string, cardholder: Cardholder, form: File) {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders`;
    const formData: FormData = new FormData();
    formData.append('cardholder', JSON.stringify(cardholder));
    if (form) {
      formData.append('form', form);
    }
    return this.http.post<Cardholder>(url, formData);
  }

  createBatchCardholder(programId: string, file: File) {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/upload`;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<any>(url, formData);
  }

  updateCardholder(programId: string, cardholderId: string, cardholder: Cardholder) {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/${cardholderId}`;
    return this.http.patch<Cardholder>(url, cardholder);
  }


  deleteCardholder(programId: string, cardholderId: string, terminationDate?: string) {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/${cardholderId}`;
    let params = new HttpParams();
    if (terminationDate) {
      params = params.set('schedule', terminationDate);
    }
    return this.http.delete<Cardholder>(url, { params });
  }

  reportLostCardholder(programId: string, cardholderId: string, body: any) {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/${cardholderId}/lost`;
    return this.http.post<any>(url, body);
  }

  reportDisputedTransaction(programId: string, cardholderId: string, body: any) {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/${cardholderId}/query`;
    return this.http.post<any>(url, body);
  }

  selectBenefits(programId: string, cardholderId: string, mealsEntertainment: boolean, livingExpenses: boolean) {
    let url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/${cardholderId}/accounts`;
    const params = [];
    if (mealsEntertainment) {
      params.push('me');
    }
    if (livingExpenses) {
      params.push('sp');
    }
    if (params.length > 0) {
      url += '?type=' + params.join(',');
    }
    return this.http.post<Cardholder>(url, undefined);
  }

  addProxyCard(programId: string, cardholderId: string, cardType: string, firstName: string, lastName: string) {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/${cardholderId}/accounts?type=proxy`;
    let body: { first_name: string, last_name: string };
    if (cardType === 'new-additional-card') {
      body = {
        first_name: firstName || '',
        last_name: lastName || ''
      };
    }
    return this.http.post<Cardholder>(url, body);
  }

  createCardOrBenefit(data) {
    const { programId, cardholderId, proxy, mealsEntertainment, livingExpenses, firstName, lastName } = data;
    let url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/${cardholderId}/accounts`;

    // Add different type of cards we are creating to url string as required
    const types = [];
    if (proxy) { types.push('proxy'); }
    if (mealsEntertainment) { types.push('me'); }
    if (livingExpenses) { types.push('sp'); }
    if (types.length > 0) {
      url += '?type=' + types.join(',');
    }

    // Finally attach the first and last name of the body if requried
    let body: { first_name: string, last_name: string };
    if (firstName && lastName) {
      body = {first_name: firstName, last_name: lastName};
    }

    // Make our API request
    return this.http.post<any>(url, body);
  }

  getMobileVerifyCode(programId: string, cardholderId: string) {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/${cardholderId}/verifyphone`;
    return this.http.get<any>(url);
  }

  postMobileVerifyCode(programId: string, cardholderId: string, code: string) {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/${cardholderId}/verifyphone`;
    const body = {
      code
    };
    return this.http.post<any>(url, body);
  }

  testCardholderEmailAvailable(email: string) {
    const url = `${environment.eml_sdk_api_url}users/check/${email}`;
    return this.http.get<any>(url);
  }

  testEmployeeIdAvaliable(employeeId: string, clientId: string, cardholderId?: string)
  {
    let url = `${environment.eml_sdk_api_url}users/check-id/${employeeId}/client/${clientId}`;
    if(cardholderId)
    {
      url = `${url}?cardholder_id=${cardholderId}`;
    }
    return this.http.get<any>(url);
  }

  approveKYC(programId: string, cardholderId: string, kycReference?: string) {
    let url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/${cardholderId}/kyc/approve`;
    if (kycReference) {
      url = `${url}?reference=${kycReference}`
    }
    return this.http.post<any>(url, null);
  }

  rejectKYC(programId: string, cardholderId: string, kycReference?: string) {
    let url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/${cardholderId}/kyc/reject`;
    if (kycReference) {
      url = `${url}?reference=${kycReference}`
    }
    return this.http.post<any>(url, null);
  }

  retryKYC(programId: string, cardholderId: string, kycReference?: string) {
    let url = `${environment.eml_sdk_api_url}programs/${programId}/cardholders/${cardholderId}/kyc/retry`;
    if (kycReference) {
      url = `${url}?reference=${kycReference}`
    }
    return this.http.post<any>(url, null);
  }

  getKYCDocument(cardholderId: string, documentId: string) {
    const url = `${environment.eml_sdk_api_url}files/identity/${documentId}`;
    const response = this.http.get<any>(url, {responseType: 'blob' as 'json', observe: 'response' })
    return response;
  }
}
