import { createSelector } from '@ngrx/store';
import { AppState, RootState } from './root.state';
import { UserPermissions } from '../models/user-permissions';
import { Cardholder, PersonalDetails } from '../../protected/shared-services-module/models';
import { CardholderState } from './init.cardholder.usertype/init.cardholder.usertype.state';
import { EmlAdminState } from './init.emladmin.usertype/init.emladmin.usertype.state';
import {clientDashboardStateSelector} from '../../protected/dashboard-module/store/client-dashboard-selectors';

export const rootStateSelector = createSelector(
  (state: AppState) => state.root
);

export const userPermissionsSelector = createSelector(
  rootStateSelector,
  (state: RootState) => state.userPermissions
);

export const bootstrapErrorSelector = createSelector(
  rootStateSelector,
  (state: RootState) => state.bootstrapError
);

export const routePermissionsSelector = createSelector(
  rootStateSelector,
  (state: RootState) => state.routePermissions
);

export const programPermissionsSelector = createSelector(
  rootStateSelector,
  (state: RootState) => state.programPermissions
);

export const userTypeSelector = createSelector(
  userPermissionsSelector,
  (state: UserPermissions) => state ? state.user_type : null
);

export const emlRoleSelector = createSelector(
  userPermissionsSelector,
  (state: UserPermissions) => state ? state.eml_role : null
);
export const clientIdSelector = createSelector(
  userPermissionsSelector,
  (state: UserPermissions) => state ? state.client_id : null
);

export const clientNameSelector = createSelector(
  userPermissionsSelector,
  (state: UserPermissions) => state ? state.client_name : null
);

export const clientLogoSelector = createSelector(
  userPermissionsSelector,
  (state: UserPermissions) => state ? state.client_logo : null
);

export const clientAllowCardholderMigrationsSelector = createSelector(
  userPermissionsSelector,
  (state: UserPermissions) => state ? state.client_allow_cardholder_migrations : null
);

export const clientAllowEmployeeIdRequiredSelector = createSelector(
  userPermissionsSelector,
  (state: UserPermissions) => state ? state.client_require_employee_id_enabled : null
);

export const clientAllowUniqueEmployeeIdsSelector = createSelector(
  userPermissionsSelector,
  (state: UserPermissions) => state ? state.client_unique_employee_id_enabled : null
);

export const sideMenuIsOpenSelector = createSelector(
  rootStateSelector,
  (state: RootState) => state.sideMenuIsOpen
);

export const userProgramsSelector = createSelector(
  rootStateSelector,
  (state: RootState) => state.userPrograms
);

export const selectedSidebarProgramSelector = createSelector(
  rootStateSelector,
  (state: RootState) => state.selectedSidebarProgram
);

export const selectedSidebarClientSelector = createSelector(
  rootStateSelector,
  (state: RootState) => state.selectedSidebarClient
);

export const selectedSidebarClientNameSelector = createSelector(
  selectedSidebarClientSelector,
  (state: any) => state ? state.name : null
);

export const cardholderSelector = createSelector(
  rootStateSelector,
  (state: RootState) => state.cardholder
);

export const cardholderProgramSelector = createSelector(
  rootStateSelector,
  (state: RootState) => state.program
);

export const cardholderAccountKeySelector = createSelector(
  cardholderSelector,
  (state: Cardholder) => state ? state.client_account_key : null
);

export const cardholderProgramTypeSelector = createSelector(
  cardholderSelector,
  (state: Cardholder) => state ? state.program_type : null
);

export const cardholderPersonalDetailsSelector = createSelector(
  cardholderSelector,
  (state: Cardholder) => state ? state.personal_details : null
);

// ************************************************************************
// CARDHOLDER GLOBAL STATE SELECTORS
// ************************************************************************
export const cardholderStateSelector = createSelector(
  rootStateSelector,
  (state: RootState) => state.cardholderState
);

export const cardholderAccountsSelector = createSelector(
  cardholderStateSelector,
  (state: CardholderState) => state ? state.accounts : []
);

export const cardholderTransactionMapSelector = createSelector(
  cardholderStateSelector,
  (state: CardholderState) => state ? state.accountTransactionMap : null
);

export const cardholderInactiveRedirectUrlSelector = createSelector(
  cardholderStateSelector,
  (state: CardholderState) => state ? state.cardholderInactiveRedirectUrl : null
);

export const cardholderNameSelector = createSelector(
  cardholderPersonalDetailsSelector,
  (state: PersonalDetails) => state ? `${state.title} ${state.fname} ${state.lname}` : null
);

// ************************************************************************
// EML ADMIN GLOBAL STATE SELECTORS
// ************************************************************************
export const emlAdminSelector = createSelector(
  rootStateSelector,
  (state: RootState) => state.emlAdminState
);

export const emlAdminClientsSelector = createSelector(
  emlAdminSelector,
  (state: EmlAdminState) => {
    return state && state.clients ? state.clients : []
  }
);

export const emlAdminClientsInFlightSelector = createSelector(
  emlAdminSelector,
  (state: EmlAdminState) => state ? state.clientsInFlight : false
);

export const emlAdminClientsErrorSelector = createSelector(
  emlAdminSelector,
  (state: EmlAdminState) => state ? state.clientsError : null
);
