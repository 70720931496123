import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, concat, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ProgramSearchModel, PagedSearchResult, PagedSearchCriteria } from '../models';
import { map, catchError, last } from 'rxjs/operators';
import { Campaign } from '../models/campaign';

@Injectable()
export class ProgramService {

  constructor(private http: HttpClient) { }

  getUserProgramsDetailed(criteria: PagedSearchCriteria = null, clientId: string = null, programId: string = null)
      : Observable<PagedSearchResult<ProgramSearchModel>> {
    return this.getUserProgramsInternal(false, true, criteria, clientId, programId);
  }
  getUserProgramsBasic(criteria: PagedSearchCriteria = null, clientId: string = null)
      : Observable<PagedSearchResult<ProgramSearchModel>> {
    return this.getUserProgramsInternal(true, false, criteria, clientId);
  }
  getUserProgramsBasicWithDisbursements(criteria: PagedSearchCriteria = null, clientId: string = null)
    : Observable<PagedSearchResult<ProgramSearchModel>> {
    return this.getUserProgramsInternal(true, true, criteria, clientId);
  }
  private getUserProgramsInternal(hideDetail: boolean, fetchDisbursements: boolean, criteria: PagedSearchCriteria, clientId: string, programId: string = null)
      : Observable<PagedSearchResult<ProgramSearchModel>> {
    const url = `${environment.eml_sdk_api_url}user/programs`;
    let params = new HttpParams();
    params = params.set('hide_detail', `${hideDetail}`);
    params = params.set('fetch_disbursements', `${fetchDisbursements}`);

    if (criteria) {
      params = criteria.pageNumber ? params.set('page_number', '' + criteria.pageNumber) : params;
      params = criteria.pageSize ? params.set('page_size', '' + criteria.pageSize) : params;
      params = criteria.sortByFieldName ? params.set('field', criteria.sortByFieldName) : params;
      params = criteria.sortDirection ? params.set('sort', criteria.sortDirection) : params;
    }
    if (clientId) {
      params = params.set('client_id', clientId);
    }
    if (programId) {
      params = params.set('program_id', programId);
    }
    return this.http.get<any>(url, { params }).pipe(
      map((rs: PagedSearchResult<ProgramSearchModel>) => {
        return {
          ...rs,
          results: rs.results.map((p: ProgramSearchModel) => {
            return {
              ...p,
              company_id: p.company_id ? p.company_id : null
            };
          })
        };
      })
    );
  }

  getCampaigns(programId: string)
      : Observable<ProgramCampaignsResponse> {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/campaigns`;
    return this.http.get<ProgramCampaignsResponse>(url);
  }

  updateCampaign(campaignDto: any): Observable<{}> {
    const url = `${environment.eml_sdk_api_url}programs/${campaignDto.programId}/campaigns/${campaignDto.id}`;
    return this.http.put<any>(url, campaignDto);
  }

  deleteCampaign(campaigns: any[]): Observable<any> {
    const deleteRequests: Observable<any>[] = campaigns.map(element => {
      const url = `${environment.eml_sdk_api_url}programs/${element.programId}/campaigns/${element.campaignId}`;
      return this.http.delete<any>(url).pipe(
        catchError(error => {
          // Handle errors here if needed
          console.error('Error deleting campaign:', error);
          return of(null); // Return null to continue with the next deletion
        })
      );
    });
    return concat(...deleteRequests).pipe(last());
  }

  createCampaign(campaign: any): Observable<{}> {
    const url = `${environment.eml_sdk_api_url}programs/${campaign.programId}/campaigns`;
    return this.http.post<any>(url, campaign);
  }

  transferFunds(programId: string, from: string, to: string, amount: number, description: string): Observable<{}> {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/transfer`;
    const body = {
      programId, from, to, amount, description
    };
    return this.http.post<any>(url, body);
  }
}

export interface ProgramCampaignsResponse {
  id: string;
  campaigns: Campaign[];
}
