import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, delay, switchMap } from 'rxjs/operators';
import { asyncScheduler } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  ConfirmPaymentBatchResponse,
  PaymentBatchStatusResponse,
  UploadPaymentFileResponse,
  DisbursementTransfer,
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class DisbursementsService {

  constructor(private http: HttpClient) { }

  uploadPaymentFile(programId: string, file: File): Observable<UploadPaymentFileResponse> {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/payments/upload`;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<UploadPaymentFileResponse>(url, formData);
    // return of({}).pipe(delay(1500), switchMap(v => throwError(new HttpErrorResponse({status: 500, statusText: 'Internal Server Error'}), asyncScheduler)));
  }

  confirmPaymentBatch(programId: string, batchId: string): Observable<ConfirmPaymentBatchResponse> {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/payments/batches/confirm`;
    const body = { batchId };
    return this.http.post<ConfirmPaymentBatchResponse>(url, body);
    // return of({}).pipe(delay(1500), switchMap(v => throwError(new HttpErrorResponse({status: 500, statusText: 'Internal Server Error'}), asyncScheduler)));
  }

  deletePaymentBatch(programId: string, batchId: string): Observable<null> {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/payments/batches/${batchId}`;
    return this.http.delete<null>(url);
    // return of({}).pipe(delay(1500), switchMap(v => throwError(new HttpErrorResponse({status: 500, statusText: 'Internal Server Error'}), asyncScheduler)));
  }

  getPaymentBatchTansferStatusList(programId: string, limit: number, continuation?: string): Observable<PaymentBatchStatusResponse> {
    let url = `${environment.eml_sdk_api_url}programs/${programId}/payments/batches`;
    let params = new HttpParams();
    params = limit ? params.set('limit', '' + limit) : params;
    url += continuation ? `?continuation=${continuation}` : '';
    const options = { params };
    return this.http.get<PaymentBatchStatusResponse>(url, options);
  }

  transferDisbursementsCashOut(programId: string, disbursementTransfer: DisbursementTransfer ) {
    const url = `${environment.eml_sdk_api_url}programs/${programId}/directentryout`;
    const body = {
      ...disbursementTransfer
    };
    return this.http.post<any>(url, body);
  }
}
